.layout-topbar {
 position: fixed;
 // height: 88px;
 height: 60px; // luatvq2 23/09/2022
 color: #ffffff;
 z-index: 999;
 right: 0;
 @include clearfix();
 @include linear-gradient-left($topbarLeftBgColor, $topbarRightBgColor);
 @include transition(left $transitionDuration);

 .layout-menu-button {
  // margin-top: 2rem !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.5rem;
  span {
   font-size: 25px;
  }
 }

 .topbar-app-title {
  position: absolute;
  // top: 34.09%;
  top: 50%; // luatvq2 23/09/2022
  transform: translateY(-50%);
  left: 3rem;
  color: $typography-title;
  max-width: 60%;
 }

 .layout-topbar-icons,
 .layout-topbar-icons-responsive {
  position: absolute;
  top: 50%; // luatvq2 23/09/2022
  transform: translateY(-50%);
  right: 2rem;
  float: right;
  display: flex;
  align-items: center;
  -moz-animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;

  button {
   position: relative;
   color: $topbarItemColor;
   margin-left: 7px;
   display: inline-block;
   text-decoration: none;
   @include transition(color $transitionDuration);
   overflow: visible;

   &:hover {
    color: $topbarItemHoverColor;
   }

   span {
    &.layout-topbar-icon h2 {
     font-family: $default-font !important;
     font-style: normal;
     font-weight: 400;
     font-size: 12px;
     line-height: 22px;
     display: flex;
     align-items: center;
    }
    svg {
     margin-right: 6px;
    }

    &.layout-topbar-badge {
     position: absolute;
     font-size: 10px;
     right: -5px;
     top: -5px;
     width: 16px;
     height: 16px;
     text-align: center;
     line-height: 16px;
     color: $topbarItemBadgeColor;
     background-color: $topbarItemBadgeBgColor;
     @include border-radius(50%);
    }

    &.lgn-en {
     background: url("/assets/layout/images/eng.png") no-repeat;
     display: inline-block;
     width: 31px;
     height: 20px;
    }

    &.lgn-vi {
     background: url("/assets/layout/images/vn.png") no-repeat;
     display: inline-block;
     width: 31px;
     height: 20px;
    }
   }
  }

  .layout-topbar-search {
   padding: 0;
   position: relative;
   display: inline-block;
   top: -4px;

   input {
    display: inline-block;
    border: 0 none;
    font-size: $fontSize;
    background: transparent;
    border-bottom: 2px solid $topbarSearchInputBorderBottomColor;
    outline: 0 none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $topbarSearchInputColor;
    width: 100px;
    padding: 1px 20px 1px 1px;
    margin: 0px;
    @include border-radius(2px);

    &::-webkit-input-placeholder {
     color: $topbarSearchInputColor;
     opacity: 0.7;
     @include transition(opacity $transitionDuration);
    }
    &:-moz-placeholder {
     color: $topbarSearchInputColor;
     opacity: 0.7;
     @include transition(opacity $transitionDuration);
    }
    &::-moz-placeholder {
     color: $topbarSearchInputColor;
     opacity: 0.7;
     @include transition(opacity $transitionDuration);
    }
    &:-ms-input-placeholder {
     color: $topbarSearchInputColor;
     opacity: 0.7;
     @include transition(opacity $transitionDuration);
    }
   }

   .layout-topbar-search-icon {
    font-size: 18px;
    position: absolute;
    top: -1px;
    right: 0px;
   }

   &:hover {
    input {
     border-bottom-color: $topbarItemHoverColor;
     &::-webkit-input-placeholder {
      opacity: 1;
     }
     &:-moz-placeholder {
      opacity: 1;
     }
     &::-moz-placeholder {
      opacity: 1;
     }
     &:-ms-input-placeholder {
      opacity: 1;
     }
    }

    .layout-topbar-search-icon {
     color: $topbarItemHoverColor;
    }
   }
  }

  .user-info {
   margin: 0;

   span {
    overflow: hidden;
    display: inline-block;
    img {
     width: 2.2em;
     height: 2.2em;
     border-radius: 50%;
    }
   }
  }
 }

 .layout-topbar-icons-responsive {
  border: none;
  top: 21.09%;
 }

 .layout-menu-button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: $topbarItemColor;
  @include transition(color $transitionDuration);

  &:hover {
   color: $topbarItemHoverColor;
  }
 }

 button {
  cursor: pointer;
 }
}

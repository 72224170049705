/* Place your extensions here to make migration to a new release easier. */
@import 'src/layout/scssVariables/_variables';
@import 'src/layout/sass/mixins';
@import 'src/layout/sass/typo';

.modal-header .close {
  color: #000 !important;
  outline: none !important;
}

.placeHolderColor::placeholder {
  color: #e1e1e1;
}

.headerApp {
  background-image: linear-gradient(to right, #174f9a, #174f9a);
}

.headerDisplayBox {
  background-image: linear-gradient(to right, #174f9a, #174f9a);
}

.headerModal {
  background: #174f9a !important;
}

.buttonStyle {
  background: #174f9a !important;
  color: #ffffff !important;
  border: none !important;
  font-size: 13px !important;
  text-align: center !important;
}
.buttonStyle i {
  font-size: 14px !important;
  margin-right: 5px !important;
  margin-left: 0px;
}

.fieldsetStyle {
  padding: 0px 10px 10px 10px;
  border: 2px solid #174f9a;
  border-radius: 5px;
}

.fieldsetStyle legend {
  color: #174f9a;
  font-size: 18px;
  width: auto;
  padding: 0px 5px 5px 5px;
}

.p-highlight {
  background-color: $mainHighLight !important;
  color: $typoWhite !important;
  transition: all .25s ease-in-out;
  &.added,
  &.removed {
    background-color: unset !important;
    .pi:not(.pi-chevron-right):not(.pi-chevron-down) {
      color: unset !important;
    }
  }
  &.added > td {
    color: $success-primary-color;
    .pi {
      color: $success-primary-color;
    }
  }
  &.removed > td {
    color: $error-primary-color;
    .pi {
      color: $error-primary-color;
    }
  }
}

b.requireStyle {
  color: red;
}

// .scrollPanel {
//  overflow-y: auto;
//  overflow-x: auto;
// }
// .scrollPanel::-webkit-scrollbar {
//  -webkit-appearance: none;
//  width: 10px;
//  height: 10px;
// }

.actionTable {
  background: none;
  color: #174f9a;
  outline-color: #d1d1d1 !important;
  border: transparent;
  box-shadow: transparent;
  padding: 1px 1px 0px 2px;
  margin-right: 2px;
  margin-left: 2px;
}
.actionTable:disabled {
  opacity: 0.5 !important;
}

.modal-body {
  margin: 0px 0px 0px 0px !important;
  padding: 0px !important;
}

.p-datatable-scrollable-header-box {
  margin-right: 0px !important;
}
.p-datatable-scrollable-body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: $scrollbarWidth;
  height: $scrollbarWidth;
}

.buttonPaddingStyle {
  width: 30px;
  cursor: pointer;
}
.buttonPaddingStyle i {
  font-size: 20px;
  color: #bcbcbc;
  margin: 3px 0px 0px 0px;
}

.ant-select-selection-item {
  margin-top: 0px !important;
}

// het phan cua report //

.navbar {
  // position: fixed;
  width: 100vw;
  top: 0;
  display: grid;
  grid-template-columns: auto auto;
  padding: 0;
  background-color: $color-background;
  justify-content: unset !important;
  z-index: 1000;
  border-bottom: 1px solid $grey-3-background-color;
  @include combineBreakPointUnit(
    height,
    px,
    $navbarHeight1,
    $navbarHeight2,
    $navbarHeight3,
    $navbarHeight4,
    $navbarHeight5,
    $navbarHeight6
  );

  &__left {
    grid-column-start: 1;
    grid-column-end: 2;
    border-right: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    display: flex;
    @include combineBreakPointUnit(
      height,
      px,
      $navbarHeight1,
      $navbarHeight2,
      $navbarHeight3,
      $navbarHeight4,
      $navbarHeight5,
      $navbarHeight6
    );
    @include combineBreakPointUnit(
      width,
      px,
      $navbarHeight1,
      $navbarHeight2,
      $navbarHeight3,
      $navbarHeight4,
      $navbarHeight5,
      $navbarHeight6
    );
    justify-content: center;
    align-items: center;

    svg {
      height: 50%;
      width: 50%;
    }
  }

  &__right {
    grid-column-start: 2;
    grid-column-end: 100;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include combineBreakPointUnit(
      height,
      px,
      $navbarHeight1,
      $navbarHeight2,
      $navbarHeight3,
      $navbarHeight4,
      $navbarHeight5,
      $navbarHeight6
    );

    &--title {
      display: flex;
      align-items: center;
      // @include combineBreakPointUnit(
      //  font-size,
      //  px !important,
      //  11,
      //  12,
      //  13,
      //  14,
      //  15,
      //  20
      // );
      // font-size: 10px !important;

      @extend .typo__title--1;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100vw - 200px);

      // @extend .typo__title--5;
      transition: 0.1s all cubic-bezier(0.65, 0.55, 0.54, 0.64);
    }
    .p-menuitem-link {
      padding: 8px 5px !important;
      & > * {
        font-size: 14px;
      }
    }
    &--profile {
      display: flex;
      padding: 0 10px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: $borderColor;
      }

      .profile {
        &__dropdown {
          margin-right: 16px;
          @include centerFlex;

          button {
            border: none;
            background-color: transparent;

            &:focus,
            &:active {
              outline: none;
            }
          }
        }

        &__label {
          display: flex;
          justify-content: center;
          align-items: center;

          .position {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            flex-grow: 0;
            > div {
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              overflow: hidden;

              // position: absolute;
              text-align: center;
              height: 100%;
              /* top: 100%; */
              // transform: translate(0%, 34%);
              // display: flex;
              align-items: flex-end;
              padding-bottom: $paddingSize;
              @extend .typo__sub--3;
            }
          }

          &--text {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-right: 16px;
            height: 100%;
            .name {
              display: flex;
              align-items: center;
              flex-shrink: 0;
              flex-grow: 1;
              > div {
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                @extend .typo__title--3;
              }
            }
          }

          &--avatar {
            img {
              height: ($navbarAvatarHeight1 + 'px');
              @include combineBreakPointUnit(
                height,
                px,
                $navbarAvatarHeight1,
                $navbarAvatarHeight2,
                $navbarAvatarHeight3,
                $navbarAvatarHeight4,
                $navbarAvatarHeight5,
                $navbarAvatarHeight6
              );
            }
          }
        }
      }
    }
  }
}

.no-vertical {
  &::after,
  &::before {
    content: '';
    opacity: 0;
    display: none;
  }
}

ul.layout-menu {
  span {
  }

  li:not(.active-menuitem) span {
    &:hover {
      opacity: 0.7;
    }
  }

  li {
    border-top: 1px solid $borderColor;
  }

  & li > ul > li {
    span {
      &:hover {
      }
    }
  }

  & li {
    padding: 15px 0;

    &.active-menuitem > ul {
      margin-top: 15px;
    }

    & > a {
      padding: 0 !important;
      border: none !important;
      span {
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}

.appsubmenu-dots {
  max-width: 100%; /* the element needs a fixed width (in px, em, %, etc) */
  overflow: hidden; /* make sure it hides the content that overflows */
  white-space: nowrap; /* don't break the line */
  text-overflow: ellipsis; /* give the beautiful '...' effect */
  padding-right: 1em;
  // background-color: red !important;
}

.small-company-logo{
  // height: 100%;
  // width: 100%;
  height: auto;
  max-height: 60px;
  width: auto;
  margin: auto;
  max-width: 95%
}

.layout-logo .small-company-logo{
  max-width: calc(91% - 40px);
}

.layout-footer {
    font-family: $default-font !important;
    @include transition(margin-left $transitionDuration);
    justify-content: space-between;
    align-items: center;
    padding: 0 !important;
    // padding: 5px 40px 5px 0 !important;
    width: 100%;
    position: relative;
    margin-top: auto;
    bottom: 0;
    background-color: $grey-3-background-color;
    
    display: flex;
    flex-grow: 0 !important;
    flex-shrink: 0 !important;

    // @include combineBreakPointUnit(
    //     height,
    //     px !important,
    //     $navbarHeight1,
    //     $navbarHeight2,
    //     $navbarHeight3,
    //     $navbarHeight4,
    //     $navbarHeight5,
    //     $navbarHeight6
    //   );

    .footer-text {
        @extend .typo__body-text--1;
        vertical-align: middle;
        padding: $cardBodyPadding;
    }

    img {
        vertical-align: middle;
    }

    .footer-link {
        list-style: none;
        text-decoration: underline;
        width: 15%;
        flex: 1;
        @include responsive('0px', '1524px') {
            width: 30%;
        }
        display: flex;
        justify-content: flex-end;
        text-align: center;
        align-items: center;
        margin: 0 !important;
        padding: 0 !important;
        > .footer-item {
            padding: $cardBodyPadding;
            > a {
                color: $typoSecondaryText;
                @extend .typo__link--1;
                &:hover {
                    color: black;
                }
            }
        }
    }
}

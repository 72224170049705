@import '../../src/components/common/CommonInputText/CommonInputText.scss';
@import '../../src/components/common/CommonSelect/CommonSelect.scss';

// custom imported from admin-dxg

body {
    background-color: $color-background;
    font-size: 13px;
    // line-height: 1 !important;
}

main.main {
    overflow: hidden;
}

header.app-header {
    background: url('/assets/img/header.png');
}

@media (max-width: 991.98px) {
    .app-header .navbar-brand {
        position: absolute;
        top: 0;
        left: 0px;
        margin-left: 20px;
        z-index: -1;
    }
}

.table {
    th,
    td {
        vertical-align: middle;
        padding: 3px;
    }
}

.iconGrid {
    padding: 8px !important;

    svg {
        font-size: 16px;
    }
}

.scrolly_table {
    white-space: nowrap;
    overflow: auto;
}

table.dashboard {
    table-layout: fixed;
    border-spacing: 0px;
    cursor: context-menu;

    thead {
        th {
            background-color: $mainHighLight;
            color: #fff;
            text-align: center !important;
            text-transform: uppercase;
            font-size: 13px;
            line-height: 25px;
            padding: 3px;
        }
    }

    tbody {
        position: relative;

        tr {
            td {
                border: 0.5px solid #ccc;
                padding: 0.5px;
            }
        }
    }

    .fixed.freeze {
        z-index: 5;
        position: relative;
    }

    .fixed.freeze_vertical {
        z-index: 4;
        position: relative;
    }

    .fixed.freeze_horizontal {
        z-index: 2;
        position: relative;
        background-color: #ddd;
        text-align: center !important;
    }
}

.tooltip-booking,
.tooltip-room {
    display: none;
    background-color: rgba(0, 0, 0, 0.75);
    color: $typoWhite;
    border-radius: 6px;
    padding: 5px 5px;
    top: 0;
    position: absolute;
    box-shadow: #555 2px 2px 5px;
    line-height: 15px;
    font-size: 11px;
    white-space: pre-wrap;
    text-align: left;
    z-index: 3;
}

.tooltip-room {
    top: 10px;
}

.tooltip-top-left::before {
    content: ' ';
    position: absolute;
    top: -25px;
    right: 25px;
    border-width: 5px;
    width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
}

.tooltip-top-right::before {
    content: ' ';
    position: absolute;
    top: -25px;
    left: 25px;
    border-width: 5px;
    width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
}

.tooltip-left::after {
    content: ' ';
    position: absolute;
    top: 15px;
    left: 100%;
    border-width: 5px;
    height: 5px;
    border-style: solid;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.75);
}

.tooltip-right::before {
    content: ' ';
    position: absolute;
    top: 15px;
    right: 100%;
    border-width: 5px;
    height: 5px;
    border-style: solid;
    border-color: transparent rgba(0, 0, 0, 0.75) transparent transparent;
}

.booking-card {
    white-space: pre-wrap;
    color: $color-background;
    position: absolute;
    margin-left: 3px;
    line-height: 15px;
    border-radius: 5px;
    box-shadow: #999 2px 2px 3px;
    padding: 5px;
    font-size: 11px;
    cursor: context-menu;
    z-index: 1;
    text-align: left;
    overflow-y: hidden;
}

.card-BUSY {
    background-color: #0095bb;
}

.card-BUSY:hover {
    background-color: #007491;
}

.card-HOLD {
    background-color: #bb8807;
}

.card-HOLD:hover {
    background-color: #916907;
}

.card-STARTED {
    background-color: #d04443;
}

.card-STARTED:hover {
    background-color: #ac4140;
}

.card-DONE {
    background-color: #8d8d91;
}

.card-DONE:hover {
    background-color: #656869;
}

.table-header-wrapper {
    background-color: $mainHighLight;

    //background-color: #f27228;
    th {
        color: #fff;
        text-align: center !important;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 25px;
    }
}

.date-picker .ant-input {
    border: none;
    border-radius: 0;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.87);
}

.time-picker .ant-time-picker-input {
    border: none;
    border-radius: 0;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.87);
}

.ant-table {
    table {
        //border: 1px solid #c8ced3;
        font-size: 14px;

        .ant-table-column-title {
            font-size: 12px;
        }
    }

    .ant-table-placeholder {
        //border: 1px solid #e8e8e8 !important;
    }

    thead.ant-table-thead > tr {
        background-color: $mainHighLight;

        th {
            padding: 6px 10px !important;
            //border: 1px solid #c8ced3;
            color: $color-background;
            text-transform: uppercase;
        }
    }

    .ant-table-tbody > tr > td {
        padding: 6px 10px !important;
    }
}

.react-bs-table-pagination {
    height: 30px !important;
}

::-webkit-input-placeholder {
    font-size: 14px;
}

.switch-sm {
    height: 18px !important;
    width: 40px;

    .switch-slider::before {
        height: 12px !important;
        width: 12px !important;
    }
}

.css-kf3xp7-singleValue {
    font-size: 14px !important;
}

.css-1l8pbso-singleValue {
    font-size: 14px !important;
}

#standard-name {
    font-size: 14px;
}

.spanSwicth {
    vertical-align: middle;
    margin-top: 23px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    font-weight: 400;
    font-family: $default-font !important;
    line-height: 1.46429em;
}

.displayBox {
    background-color: $mainHighLight;
    border: none;
    padding-top: 1px;
    padding-bottom: 1px;

    .btn {
        color: $color-background;
    }
}

.form-group {
    margin-bottom: 0px !important;

    label {
        font-size: 13px;
        white-space: nowrap;
    }

    input {
        font-size: 13px !important;
    }
}

.card_on::before {
    box-sizing: unset;
}

.switch {
    vertical-align: middle;
}

#upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#addImage:hover {
    display: block;
}

.btn {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    text-transform: uppercase;
    font-size: 13px;
}

.errorMessage {
    color: $error-primary-color;
}

.card {
    margin-bottom: 0px;

    .card-header {
        padding-left: 4px;
    }
}

.anticon {
    vertical-align: 2px !important;
}

.sidebar {
    .nav-dropdown-toggle::before {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .nav-dropdown-items {
        .nav-link {
            padding-left: 25px;
        }

        .nav-link.active {
            background-color: $mainHighLight;

            .nav-icon {
                color: #ffffff;
            }
        }
    }
}

.mr-notify.ant-notification-notice {
    background-color: rgba(0, 0, 0, 0.75);

    .ant-notification-notice-content,
    .ant-notification-notice-message,
    .ant-notification-close-icon {
        color: $color-background;
    }
}

legend {
    display: block;
    padding: 0;
    width: auto !important;
    margin-bottom: 0rem;
    font-size: 13px !important;
    font-weight: bold !important;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

.p-fieldset,
.p-fieldset .p-fieldset-legend {
    padding: 0.2em 0.6em;
}

.p-autocomplete {
    width: auto;
    zoom: 1;
    cursor: pointer;
    box-shadow: none;
    position: relative;
    display: flex;
}

.p-autocomplete .p-autocomplete-dropdown {
    height: auto;
    width: 2em;
    margin-right: 0;
    vertical-align: top;
}

.p-autocomplete-multiple-container.p-inputtext {
    clear: left;
    cursor: text;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0 1.5em 0 0.25em;
    width: 100%;
}

label {
    display: inline-block;
    margin-bottom: 0px;
    color: #000;
}

body .p-component {
    font-family: $default-font !important;
    font-size: 13px;
    text-decoration: none;
}

#tooltip-table {
    display: none;
    background-color: $mainHighLight;
    border-radius: 3px;
    padding: 3px 5px;
    box-shadow: #555 2px 2px 5px;
    position: absolute;
    z-index: 9999;
    color: $color-background;
    opacity: 0;
    transition: visibility 0.2s, opacity 0.2s linear;
    font-family: $default-font !important;
    font-size: 13px;

    & * {
        background-color: transparent !important;
        color: white !important;
    }
}

.colToggle > .p-multiselect-trigger {
    * {
        font-size: $datatablePaginatorHeight;
        > * {
            width: $datatablePaginatorHeight;
        }
    }
    .pi-chevron-down {
        &:before {
            content: '\E949' !important;
            color: orange !important;
        }
    }
}

.colToggle > .p-multiselect-item.p-highlight {
    color: $color-background;
    background-color: $grey-3-background-color;
}

.colToggle > .p-multiselect-panel {
    right: 0 !important;
    left: unset !important;
    overflow: auto;

    .p-multiselect-item.p-highlight {
        background-color: $color-background !important;
    }

    .p-multiselect-item:hover {
        label {
            color: $mainHighLight;
            font-weight: bold;
            cursor: pointer;
        }

        background-color: $grey-3-background-color !important;
    }
}

.colToggle > .p-up {
    bottom: 0 !important;
    top: unset !important;
}

.colToggle > .p-down {
    top: 0 !important;
    bottom: unset !important;
}

body .colToggle {
    border: 0;
}

.p-inputtext .p-component {
    height: 25px;
}

.breadcrumb-wrapper {
    @extend .flex-100-row;
    height: auto !important;
    align-items: stretch;
    // flex-grow: 1 !important;
    flex-shrink: 0 !important;
}

.breadcrumb {
    font-size: 14px;
    position: relative;
    // margin-bottom: 32px;
    margin-bottom: 10px; // luatvq2 23/09/2022
    //top: -4em;
    //left: -1.5em;
    //border-bottom: 1px solid #c8ced3;
    //font-weight: bold;
    //font-size: 13px;
    //background-color: #f8f8ff;
    //padding: 0.5rem 1.25rem;
    //top: -16px;
    //left: -16px;
    //margin-right: -32px;
    //margin-bottom: -0.5rem;

    .breadcrumb-item > a {
        color: #555 !important;
    }

    .breadcrumb-item {
        color: $mainHighLight !important;
    }

    .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: #73818f;
        content: '>' !important;
    }
    > ul {
        > li {
            height: auto;
            @extend .center-row-pi;
        }
    }
}

.p-spinner > .p-spinner-input {
    width: 100%;
}

//Style hien thi ten ung dung
.topbar-app-title {
    display: inline-block;
    font-family: $default-font;
}

//Style hien thi bieu tuong
.topbar-app-logo {
    margin-right: 15px;
}

body .p-multiselect .p-multiselect-label {
    padding: 0.429em;
    padding-right: 2em;
    font-weight: normal !important;
    color: $mainHighLight !important;
    margin-top: 0px !important;
    font-family: $default-font !important;
    font-size: 13px !important;
}

.ant-calendar-picker-input.ant-input {
    line-height: 1.5;
    color: $mainHighLight !important;
    background-color: #ffffff;
    font-family: $default-font !important;
    font-size: 13px !important;
    height: 27px;
    padding: 0.429em;
}

.ant-calendar-input {
    width: 100%;
    height: 22px;
    outline: 0;
    cursor: auto;
    color: $mainHighLight !important;
    background-color: $color-background;
    border: none;
    font-family: $default-font !important;
    font-size: 13px !important;
}

.ant-input[disabled] {
    color: $mainHighLight !important;
    background-color: $color-background !important;
    cursor: auto !important;
    // opacity: 0.5 !important;
    font-family: $default-font !important;
    font-size: 13px !important;
}

.ant-calendar-picker-icon {
    display: inline-block;
    color: $mainHighLight !important;
    opacity: 0.5 !important;
    font-size: 14px;
    line-height: 1;
}

.ant-calendar-picker-clear:hover {
    color: rgba(0, 174, 239, 0.68) !important;
}

//end styling for split panel
.input-number-button {
    .p-spinner-button {
        display: none;
    }
}

.dropdown350 {
    width: 350px !important;
}

.dropdown400 {
    width: 400px !important;
}

.dropdown500 {
    width: 500px !important;
}

.panel-no-header {
    border: 1px solid $borderColor;
    border-radius: 3px;
    padding: 5px;
    margin: 5px 0px 5px 0px;
}

body .p-fieldset .p-fieldset-legend a {
    padding: 0px !important;
    border: none !important;
    color: #333333;
    background-color: $color-background !important;
    font-weight: 700;
    border-radius: 3px;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s;
}

body .p-fieldset .p-fieldset-legend a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}

.ant-tree.ant-tree-show-line.hide-file-icon li span.ant-tree-switcher-noop {
    background: transparent;
}

.ant-tree.ant-tree-show-line.hide-file-icon li:before {
    content: ' ';
    width: 1px;
    border-left: 1px solid $borderColor;
    height: 100%;
    position: absolute;
    left: 12px;
    top: 0;
    margin: 0;
}

.ant-tree.ant-tree-show-line.hide-file-icon li:first-child:before {
    top: 6px;
    height: calc(100% - 6px);
}

.ant-tree.ant-tree-show-line.hide-file-icon li:last-child:before {
    height: 16px;
}

.ant-tree.ant-tree-show-line.hide-file-icon li:first-child:last-child:before {
    height: 10px;
}

.ant-tree.ant-tree-show-line.hide-file-icon li .ant-tree-switcher-noop > i {
    visibility: hidden;
}

.ant-tree.ant-tree-show-line.hide-file-icon li .ant-tree-switcher-noop:after {
    content: ' ';
    height: 1px;
    border-bottom: 1px solid $borderColor;
    width: 10px;
    position: absolute;
    left: 12px;
    top: 50%;
    margin: 0;
}

.noWrap {
    .ant-tree-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
    }
}

/*end custom ant tree*/

div.split-button-height button {
    height: 30px !important;
}

div.split-button-height button:disabled {
    opacity: 1;
}

.progressCustom {
    z-index: 999999999 !important;
}

.p-tabview .p-tabview-nav li a {
    float: left;
    padding: 0.1em 1em !important;
    text-decoration: none;
}

.textAreaCustom {
    height: auto !important;
}

.modal-open {
    overflow: auto !important;
    padding-right: 0 !important;
}

body .p-dialog .p-dialog-titlebar {
    border: 1px solid $borderColor;
    background-color: $mainHighLight !important;
    color: $color-background;
    padding: 0.7em;
    font-weight: 700;
    border-bottom: 0 none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100% !important;
}

.react-datepicker__input-container {
    &:after {
        content: '\f073';
        color: $mainHighLight !important;
        display: inline-block;
        padding-right: 10px;
        position: absolute;
        text-align: left;
        top: 15%;
        right: 0;
    }

    input {
        // font-size: 13px;
        // font-family: $default-font !important;
        // line-height: 16px;
        @extend .typo__label--4;
        border-radius: 4px;
        box-shadow: inset 0 2px 2px #e9e9e9;
        border: 1px solid $borderColor;
        padding: 6px 10px 5px;
        height: 27px;
        width: 100%;
        color: $mainHighLight !important;
    }
}

.btn-position {
    margin: 5px !important;
}

.btn-position-right {
    margin-right: 5px !important;
}

.p-tooltip {
    z-index: 9999 !important;

    &-arrow {
        border-right-color: $mainHighLight !important;
    }

    &-text {
        background-color: $mainHighLight !important;
    }
}

div.newNode:after {
    color: $error-primary-color;
    top: -6px;
    margin-left: 7px;
    content: 'tick and new';
    font-family: $default-font;
    font-weight: bold;
    font-size: 8pt;
    font-style: italic;
    position: relative;
    display: inline-block;
}

.breadcrumb {
    .breadcrumbActive span {
        color: $mainHighLight !important;
    }
}

.tMenu {
    .p-overlaypanel-content {
        padding: 0px !important;
    }

    .layout-menu-container {
        overflow-y: auto;
        max-height: 80vh;
        padding-bottom: 5px;

        a:hover {
            color: $mainHighLight;
        }

        .active-menuitem > a {
            color: $mainHighLight;
        }

        span {
        }

        a {
            color: #232428;
            border: none !important;
        }
    }
}

.home-document {
    display: flex;
    justify-content: center;
    margin-top: 15px;

    a {
        width: fit-content;
        display: flex;
        align-items: center;
        color: $mainHighLight;
    }

    p {
        margin-bottom: 15px;
        font-size: 18pt;
        font-weight: bold;
        font-family: $default-font !important;
    }

    a:hover {
        color: $mainHighLight;
        transition: 0.8s;
    }

    img {
        width: 34px;
        margin-right: 15px;
    }
}

.p-input-overlay-visible {
    z-index: 99999 !important;
}

.p-contextmenu {
    z-index: 10000 !important;
    min-width: 12.5rem;

    .p-menuitem-text {
        white-space: nowrap;
    }
    .p-menuitem-link {
        display: flex;
    }
}

.my-icon-lock:after {
    content: '\e95f';
}

/*.p-treenode {
  .pi-unlock {
      color: orange !important;
  }
}

.p-treenode-label:after {
  content: "\e95f";
  top: -5px;
  left: 2px;
  position: relative
}*/

/*div.tickNode:after {
  content: "\e909";
  top: -6px;
  margin-left: 7px;
  font-weight: bold;
  font-size: 10pt;
  position: relative;
  display: inline-block;
  color: green;
}*/

div.disableNode:after {
    content: '\e95f';
    font-family: 'primeicons';
    top: -6px;
    margin-left: 7px;
    font-weight: bold;
    font-size: 10pt;
    position: relative;
    display: inline-block;
    color: $error-primary-color;
}

.modal-header > button.close:focus {
    outline: 0 none;
    outline-offset: 0;

    span {
        outline: 0 none;
        outline-offset: 0;
        // box-shadow: 0 0 0 0.2em #8dcdff;
        border-color: $mainHighLight;
        border-width: 1px !important;
    }
}

.ant-picker {
    padding: 4px 0.429em 4px;
}

.ant-picker.ant-picker-disabled {
    background: $color-background !important;
}

.ant-picker-disabled .ant-picker-input > input {
    color: $textReadOnly !important;
}

.ant-picker .ant-picker-input > input {
    color: $mainHighLight;
}

.p-inputtextarea {
    //color: $mainHighLight !important;
    color: $menuitemDarkActiveBgColor !important;
}

.p-inputtextarea:read-only {
    color: $textTagBlue !important;
    cursor: not-allowed;
}

.rowStyleSeenNotify {
    background-color: #daf6ff !important;
}

body .p-button:hover {
    background-color: $secondary-color-1-hover;
    border: 1px solid $secondary-color-1-hover;
}

body .p-button {
    margin: 0;
    color: $color-background;
    background-color: $secondary-color-1;
    border: 1px solid $secondary-color-1;
    font-size: 14px;
    transition: background-color 0.2s, box-shadow 0.2s;
    border-radius: $borderRadius;
    padding: 1.5px 0px;
    @extend .typo__button-text--2;
    // @extend .responsive-text;
}

body .p-button.p-button-text-icon-left .p-button-text {
    padding: $buttonPaddingWIcon;
}

body .p-paginator .p-paginator-pages .p-paginator-page {
    color: $title-color;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: $mainHighLight;
    color: $color-background;
    border-radius: $borderRadius;
}

body .p-paginator .p-paginator-page {
    background-color: $grey-3-background-color;
    border-radius: $borderRadius !important;
}

body .p-paginator .p-paginator-element {
    background-color: $grey-3-background-color;
    border-radius: $borderRadius;
}

body .p-paginator {
    background-color: $color-background;
    height: 78px;
}

body .p-tree .p-tree-filter-container .p-tree-filter-icon {
    top: 50%;
    left: auto;
    margin-top: -0.5em;
    right: 0.8em;
    color: $mainHighLight;
}

.p-tree-filter-container input {
    padding: 20px 18px;
    @extend .typo__placeholder--1;
    color: #232428;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight a {
    background-color: $mainHighLight;
    border: 1px solid $mainHighLight;
    color: #ffffff;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight a:hover {
    background-color: $mainHighLight !important;
    border: 1px solid $mainHighLight !important;
    color: #ffffff;
}

.btn-primary {
    color: #fff;
    background-color: $mainHighLight;
    border-color: $mainHighLight;
}

.btn-primary:hover {
    color: #fff;
    background-color: $mainHighLight !important;
    border-color: $mainHighLight !important;
}

.modal-danger .modal-header {
    color: #fff;
    // background-color: $mainHighLight;
    background-color: #ffffff;
}

body .p-fieldset {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
}

.modal-dialog.modal-danger {
    .modal-header {
        background-color: $error-primary-color !important;
    }
}

.ant-tag {
    border: none !important;
    text-align: center;
    font-family: Roboto;
    @extend .typo__label--4;
    border-radius: 32px;
    padding: 5px 12px;

    &-red {
        color: $textTagRed !important;
        background-color: $bgTagRed;
    }

    &-green {
        color: $textTagGreen !important;
        background-color: $bgTagGreen;
    }

    &-blue {
        color: $textTagBlue !important;
        background-color: $bgTagBlue;
    }

    &-pink {
        color: $textTagPink !important;
        background-color: $bgTagPink;
    }

    &-purple {
        color: $textTagPurple !important;
        background-color: $bgTagPurple;
    }

    &-yellow {
        color: $textTagYellow !important;
        background-color: $bgTagYellow;
    }

    &-orange {
        color: $textTagYellow !important;
        background-color: $bgTagYellow;
    }
}

// Date Picker (Ant)

body .ant-picker-panel-container {
    padding: 16px 13px;
    border-radius: 4px;
    box-shadow: 0px 2px 11px -1px rgba(0, 0, 0, 0.09);
    -webkit-box-shadow: 0px 2px 11px -1px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 2px 11px -1px rgba(0, 0, 0, 0.09);

    & .ant-picker-decade-panel {
        & .ant-picker-cell .ant-picker-cell-inner {
            white-space: nowrap;
            min-width: 75px !important;
        }
    }

    & .ant-picker-panel {
        border: none;
    }

    & .ant-picker-header {
        border: none !important;
        display: grid;
        justify-content: space-between;
        grid-template-columns: auto auto auto;
        padding: 0 !important;

        & .ant-picker-header-view {
            order: -1;
            grid-column-start: 1;
            grid-column-end: 100;
            display: flex;
            @extend .typo__title--2;
            & button:not(:first-child) {
                margin-left: 5px;
            }
        }

        & .ant-picker-header-prev-btn {
            grid-column-start: 100;
            grid-column-end: 101;
        }

        & .ant-picker-header-next-btn {
            grid-column-start: 101;
            grid-column-end: 102;
        }

        & [class^='ant-picker-header-']:not(.ant-picker-header-view) {
            color: $checkedBorder;
            transform: scale(1.5);
        }

        & button[class^='ant-picker-header-super-'] {
            display: none;
        }
    }

    & td.ant-picker-cell-selected {
        .ant-picker-cell-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 9999px;
            background-color: $checkedBorder;
            color: #fff !important;

            @extend .typo__body-text--2;
        }
    }

    & td.ant-picker-cell-in-view:not(.ant-picker-cell-selected):hover,
    & td.ant-picker-cell-end:not(.ant-picker-cell-selected):hover,
    & td.ant-picker-cell-start:not(.ant-picker-cell-selected):hover,
    & td.ant-picker-cell-in-view:not(.ant-picker-cell-selected),
    & td.ant-picker-cell-end:not(.ant-picker-cell-selected),
    & td.ant-picker-cell-start:not(.ant-picker-cell-selected) {
        & .ant-picker-cell-inner {
            border-radius: 9990px;
        }
    }

    & th,
    & td {
        padding: 6px 12px;
    }

    & .ant-picker-body {
        padding: 8px 0 !important;
    }

    & .ant-picker-content {
        width: 100%;

        & thead {
            & tr th {
                @extend .typo__title--3;
                color: $typography-text;
            }
        }
    }

    & .ant-picker-footer {
        // display: none;
    }
}

body .ant-picker {
    border-radius: $borderRadius;
    border: 1px solid $borderColor !important;
}

// Ant tooltip

.ant-tooltip {
    .ant-tooltip-arrow-content {
        --antd-arrow-background-color: $info-primary-color;
    }

    .ant-tooltip-arrow {
        left: 1px;
    }

    .ant-tooltip-inner {
        background-color: $info-primary-color;
        border-radius: $borderRadius;
    }
}

// Ults

[class^='lst-btn-link'] button,
.flex-container button {
    padding: 0 !important;
    height: auto !important;
    width: auto !important;
}

[class^='lst-btn-link'],
.flex-container {
    display: flex;
    justify-content: space-around;
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
    display: none;
}

.ant-tooltip-arrow::before {
    background-color : #00aeef !important;
}
.layout-sidebar {
    background-color: $color-background !important;
}

.ant-switch-checked {
    background-color: $info-primary-color;
}

#overlay_panel {
    border-radius: $borderRadius;
    border: 0;

    &::before {
        border: 0;
        border-color: transparent;
    }

    li.typo__heading--6 {
        padding: 10px 13px;
    }
}

body .p-treetable .p-treetable-thead > tr > th,
body .p-treetable .p-treetable-tbody > tr > td {
    border-radius: 3px;
    overflow: hidden;
    // border-left: none;
    // border-right: none;
    border-color: $grey-4-background-color;
    text-align: start;

    .p-treetable-toggler.p-unselectable-text {
        scale: 0.75;
    }
}

.paddingtop-5 {
    padding-top: 5px !important;
}

.paddingbottom-5 {
    padding-bottom: 5px !important;
}

/* model-dialog start */
.modal-dialog:not(.messagebox) {
    max-height: $dialogMaxHeight !important;
    display: flex !important;
    flex-direction: column !important;
    overflow: auto;
    & > .modal-content {
        min-height: $dialogMinHeight !important;
        border-radius: $borderRadius;
        border: none;
        overflow: hidden;

        height: 100% !important;
        max-height: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        & > div:not([class^='modal-']) {
            height: 100% !important;
            max-height: 100% !important;
            overflow: scroll;
            display: flex !important;
            flex-direction: column;
            flex-grow: 1 !important;
        }
        > .modal-header {
            white-space: nowrap;
            flex-shrink: 0;

            color: $color-background;
            padding: $dialogLabelPadding;
            background-color: $color-background;
            box-shadow: inset 0px -1px 0px $grey-3-background-color;
            border: none;
            border-radius: $borderRadius $borderRadius 0px 0px;
            overflow: hidden;

            height: auto !important;
            .modal-title {
                color: $typography-title;
                @extend .typo__heading--4;
            }
        }
        .modal-body {
            flex-grow: 1 !important;
            padding: 0 .35rem 0 0 !important;
            overflow: auto;

            height: 100% !important;
            max-height: 100% !important;
            display: flex !important;
            flex-direction: column;
            background: $color-background !important;
            .form-group {
                height: 100% !important;
                max-height: 100% !important;
                display: flex !important;
                flex-direction: column !important;
                overflow: unset;
            }
            .p-tabview {
                height: 100%;
                max-height: 100%;
                display: flex !important;
                flex-direction: column !important;
                overflow: inherit;
                padding: 0 !important;
                & > .p-tabview-nav {
                    // flex-grow: 1 !important;
                    height: auto !important;
                    flex-shrink: 0;
                }
                .p-tabview-panels {
                    padding: 0 !important;
                    flex-grow: 1;
                    height: 100% !important;
                    max-height: 100% !important;
                    display: flex !important;
                    flex-direction: column !important;
                    overflow: auto;
                    & > .p-tabview-panel {
                        height: 100% !important;
                        max-height: 100% !important;
                        display: flex !important;
                        flex-direction: column !important;
                        overflow: auto;
                        padding: 0 !important;
                        flex-grow: 1;
                    }
                }
            }
        }
        .modal-footer {
            flex-shrink: 0;

            border-radius: 0px 0px $borderRadius $borderRadius;
            background-color: $color-background;
            border-top: 1px solid $borderColor;
            padding: $dialogFooterPadding;
            overflow: auto;

            height: auto !important;
        }
    }
    .Pane,
    .SplitPane {
        border-radius: 0 !important;
    }
}

/* model-dialog end */

/* field-set with flex-100 START */

fieldset.flex-100 {
    height: 100% !important;
    max-height: 100% !important;
    display: inline-flex !important;
    flex-direction: column !important;
    overflow: scroll;
    min-inline-size: auto !important;
    & > .p-toggleable-content {
        height: 100% !important;
        max-height: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        overflow: scroll;
        & > .p-fieldset-content {
            height: 100% !important;
            max-height: 100% !important;
            display: flex !important;
            flex-direction: column !important;
            overflow: scroll;
        }
    }
}

/* field-set with flex-100 END */

.no-border,
.no-border-include-child {
    border-width: 0px !important;
}
.no-border-include-child * {
    border-width: 0px !important;
}
.border-1 {
    border-width: 1px !important;
}
.border-light {
    border: 0px solid $borderColor !important;
}
.border-bottom-1 {
    border-bottom-width: 1px !important;
}

.sticky-header-100 {
    padding: $stickyHeaderPadding;
    display: flex !important;
    flex-direction: row !important;
    height: auto !important;
    width: 100%;
    position: -webkit-sticky !important;
    position: sticky !important;
    left: $stickyHeaderLeftMargin;
    justify-content: space-between;
    border: none;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom: 1px solid $borderColor;

    // header: .typo__heading--4
    &.sticky-header-100 > div:first-child {
        margin-top: auto;
        margin-bottom: auto;

        @extend .limit-word-lines;
        -webkit-line-clamp: 1;

        @extend .typo__title--3;
        // @extend .responsive-text;
    }
    .sticky-header-button {
        flex-flow: column !important;
        flex-shrink: 0 !important;
        margin-top: auto;
        margin-bottom: auto;
        .sticky-button-group {
            left: 0 !important;
            float: right;
            top: 0 !important;
            width: 100% !important;
            position: -webkit-sticky !important;
            position: sticky !important;
            display: inline;

            & > span {
                display: inline-block;
            }
        }
    }
}

.border-user {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
}

.ant-btn {
    border-color: $borderColor !important;
}

#btn-otp.p-button.p-component.p-button-text-only {
    span {
        padding: 0.45em 0.35em;
    }
}
.green {
    color: rgb(57, 238, 57) !important;
    font-weight: bold;
}

.red {
    color: rgb(255, 94, 94) !important;
    font-weight: bold;
}

.text-animation {
    transition: .5s all ease;
}

.disabled-switch {
    background: #d0d0d0;
    border-radius: $borderRadius;
    cursor: not-allowed;
}

.disabled-line {
    &::after,
    &::before {
        content: '';
        display: none;
    }
}

// notification overlay

.notification {
    & .ant-dropdown-menu-title-content {
        width: 400px;
        max-height: 500px;
        overflow-y: scroll;
    }

    & .ant-dropdown-menu-item {
    }

    & .footer {
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        text-decoration: underline;
        color: #19c4ef;
        cursor: pointer;
    }

    ul.ant-dropdown-menu {
        li:first-child,
        li:last-child {
            color: unset;
            cursor: unset;
        }
    }
}

.ant-dropdown-trigger {
    cursor: pointer;
}

.navbar__right--profile .count {
    position: absolute;
    top: -10px;
    right: -5px;
    font-size: 50%;
    color: white;
    background-color: red;
    border-radius: 50%;
    padding: 3px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 2px solid white;
}

.navbar__right--profile .none {
    display: none;
}

.navbar__right--notification > span {
    position: relative;
}
.background-masker {
    background-color: #fff;
    position: absolute;
}

.btn-divide-left {
    top: 0;
    left: 25%;
    height: 100%;
    width: 5%;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -800px 0;
    }
    100% {
        background-position: 800px 0;
    }
}

.animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 30px;
    opacity: 0.75;
    position: relative;
}

.static-background {
    background-color: #f6f7f8;
    background-size: 800px 104px;
    height: 70px;
    position: relative;
    margin-bottom: 20px;
}
.background-masker {
    background-color: #fff;
    position: absolute;
}

.btn-divide-left {
    top: 0;
    left: 25%;
    height: 100%;
    width: 5%;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -800px 0;
    }
    100% {
        background-position: 800px 0;
    }
}

.animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 30px;
    opacity: 0.75;
    position: relative;
}

.static-background {
    background-color: #f6f7f8;
    background-size: 800px 104px;
    height: 70px;
    position: relative;
    margin-bottom: 20px;
}

.navbar__right--notification {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
}

.ant-dropdown.notification {
    ul.ant-dropdown-menu.ant-dropdown-menu {
        li:nth-child(3) {
            padding: 0;
        }
    }
}

.no-header {
    .p-datatable-scrollable-header {
        display: none;
    }
}

.p-copy {
    cursor: pointer;
}

.disable-paginator {
    .p-paginator {
        display: none !important;
    }
}

a.p-cell-editor-key-helper.p-hidden-accessible {
    position: relative;
    display: flex;
    height: 30px;
    width: 100%;
    border: 2px solid rgba($subtitle-color, 0.25);
    border-radius: $borderRadius;
}

.control_row {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translate(0, -50%);
    max-width: 90%;
    overflow: hidden;
    color: rgba($grey-9-background-color, 0.75);
}

.p-editable-column.p-cell-editing {
    a {
        display: none !important;
    }
}

.p-editable-column {
    position: relative;

    &:not(:has(.no-picker)) {
        a {
            display: none;
        }
    }
}

.user-info-border.col-md-9.mt-1 {
    padding: 0 !important;
}

.ck-editor__editable_inline {
    height: auto;
}

.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding: 0 !important;
}

.modal-dialog {
    transition: all 0.285s ease-in-out !important;
}

.zoomIn-btn {
    width: 45px;
    position: absolute;
    right: 47px;
}

.zoom.modal-dialog {
    max-width: 99vw !important;
    margin: 0.25rem auto;
}

.draggin-input {
    border: 3px solid $textTagBlue !important;
}

.no-pagination {
    & .p-paginator.p-component {
        display: none !important;
    }
}

.quotes {
    border-left: 5px solid $secondary-color-1;
    padding-left: 10px;
    &:has(.col-md-4) {
        width: 35%;
    }
    .col-md-4 , .col-md-8 {
        padding: 0 !important;
    }
}

.tree-paginator {
    .p-treetable.p-component {
        overflow: hidden !important;
    }
    .p-paginator.p-component {
        height: 38px !important;
    }
    .p-treetable-tablewrapper {
        overflow: scroll !important;
    }
}

// style.css

#fullscreenoverlay {
    overflow: hidden;
}

#fullscreeneditor {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    z-index: 10000;

    .ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel-visible {
        transform: translateY(170px);
    }
}

#fullscreeneditor .ck.ck-sticky-panel__content {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
}

#fullscreeneditor .ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
#fullscreeneditor .ck.ck-editor__main {
    height: 100%;
}

.ck.ck-balloon-panel {
    z-index: 999999 !important;
}

// .ck-reset_all-excluded {

// }

.ck.ck-balloon-panel.ck-balloon-panel_position_border-side_right.ck-balloon-panel_visible.ck-powered-by-balloon {
    visibility: hidden !important;
    display: none !important;
}

.no-gap {
    .Resizer.vertical {
        margin: 0 !important;
    }
}

.ant-image {
    border-radius: 8px;
    overflow: hidden;
}

.h-100 {
    .p-card {
        // overflow: hidden !important;
    }
}

.h-95 {
    max-height: 90% !important;
}

.custom-upload {
    display: inline-flex !important;

    .ant-upload-list-text-container {
        display: none;
    }
}

.filter-header {
    .sticky-button-group {
        display: flex !important;
        align-items: center;
        justify-content: center;
        text-align: center;

        & > *:not(:last-child) {
            margin-right: 1rem;
        }
    }
}

.flex-center {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.abosulte-switch {
    position: absolute !important;
    top: 0;
    right: 0;
}


.flex-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap !important;
}

.wrapper {
    flex-grow: 1;
    display: flex;
    width: 400px;
}
.groupHeader {
    flex-direction: row !important;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0 !important;
    padding: 0 !important;
}

@media print {
    .ck.ck-toolbar__items  , .ck.ck-sticky-panel{
        display: none !important;
    }
    .section-to-print{
        // font-size: 130% !important;
    }
    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
        font-size: 15pt !important;
    }
    p {
		font-size: 12pt;
	}
}

.template-email-sms-dialog.custom-selecter {

    .ant-select-multiple.ant-select-selection-item-content {
        width: 99%;

    }

    .ant-select-selection-overflow-item {
        width: 50%;
    }

    span.ant-select-selection-item-content {
        width: 99%;
    }
}

@media print {
    .ck.ck-toolbar__items  , .ck.ck-sticky-panel{
        display: none !important;
    }
    .section-to-print{
        // font-size: 130% !important;
    }
    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
        font-size: 15pt !important;
    }
    p {
		font-size: 12pt;
	}
}

.template-email-sms-dialog.custom-selecter {

    .ant-select-multiple.ant-select-selection-item-content {
        width: 99%;

    }

    .ant-select-selection-overflow-item {
        width: 50%;
    }

    span.ant-select-selection-item-content {
        width: 99%;
    }
}

.pl-1 {
    padding-left: 1.5rem !important
}

// ! Caution : Leave this code alone
// ! Adjust this may cause unable to generate image from editor content

.ck.ck-editor__main {
    height: 100% !important;
    overflow: scroll;
    max-height: 100%;
}
.ck.ck-content.ck-editor__editable {
    height: unset !important;
    max-height: unset !important;
    overflow: unset !important;
}

.minized-tag {
    padding: 5px !important;
    line-height: 10px !important;
}
.tooltip-header {
    display: none;
}
.p-column-title {
    color: black !important;
    .tooltip-header {
        display: inline-block !important;
    }
}


.left_item {
    img {
        width: 100%;
        height: 100%;
    }
}

.popup-table {
    #primereact-datatable {
        .p-datatable-scrollable-wrapper {
            .p-datatable-scrollable-view {
                .p-datatable-scrollable-body {
                    max-height: 300px !important;
                }
            }
        }
    }
}
body #messageBox.modal-dialog {
    top: 20%;
}

.ant-image-preview-img {
  background-color: white !important;
}

.multiselect-demo .my-multiselected-item-token, .multiselect-demo .my-multiselected-empty-token {
  padding: 2px 4px;
  margin: 0 0.286em 0 0;
  display: inline-block;
  vertical-align: middle;
  height: 1.857em;
  border-radius: 3px;
}
.multiselect-demo .my-multiselected-item-token {
  background: #007ad9;
  color: #ffffff;
}
.multiselect-demo .my-multiselected-empty-token {
  background: #1dcbb3;
  color: #ffffff;
  width: 100%;
}
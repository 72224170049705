@import "layout/scssVariables/_variables.scss";
@media (min-width: 1025px) {
 .layout-wrapper {
  &.layout-overlay {
   .layout-sidebar {
    left: -$layoutMenuWidth;
   }

   .layout-topbar {
    left: 0;
   }

   .layout-menu-appear {
    display: block;
   }

   .topbar-app-title {
    left: 3.5rem;
   }

   .layout-main,
   .layout-footer {
    // margin-left: 0px;
   }

   &.layout-overlay-sidebar-active {
    & .navbar__right--title.typo__title--2 {
     transform: translateX(calc(#{$layoutMenuWidth - 40px}));
     // margin-left: 100px;
    }
    .layout-sidebar {
     left: 0px;
    }

    .layout-topbar {
     left: $layoutMenuWidth;
    }

    .layout-menu-appear {
     display: none;
    }

    .topbar-app-title {
     left: 2rem;
    }

    .layout-main,
    .layout-footer {
     margin-left: 0px;
    }
    .layout-mask {
     display: block;
    }
   }
  }

  &.layout-static {
   .layout-sidebar {
    left: 0;
   }

   .layout-topbar {
    left: $layoutMenuWidth;
   }

   .layout-menu-appear {
    display: none;
   }

   .layout-main,
   .layout-footer {
    margin-left: $layoutMenuWidth;
   }

   &.layout-static-sidebar-inactive {
    .layout-sidebar {
     left: -$layoutMenuWidth;
    }

    .layout-topbar {
     left: 0;
    }

    .layout-menu-appear {
     display: block;
    }

    .layout-main,
    .layout-footer {
     margin-left: 0;
    }
   }
  }

  .layout-mask {
   display: none;
   position: fixed;
   width: 100%;
   height: 100%;
   @include combineBreakPointUnit(
    top,
    px,
    $navbarHeight1,
    $navbarHeight2,
    $navbarHeight3,
    $navbarHeight4,
    $navbarHeight5,
    $navbarHeight6
   );
   left: 0;
   z-index: 998;
   background-color: $maskBgColor;
   @include opacity(0.7);
  }
 }
}

@media (max-width: 1024px) {
 .layout-wrapper {
  .layout-topbar {
   left: 0;
  }

  .layout-main,
  .layout-footer {
   // margin-left: 0;
  }

  .layout-sidebar {
   left: -$layoutMenuWidth;
  }

  .layout-mask {
   display: none;
   position: fixed;
   width: 100%;
   height: 100%;
   top: 7vh;
   left: 0;
   z-index: 998;
   background-color: $maskBgColor;
   @include opacity(0.7);
   @include combineBreakPointUnit(
    top,
    px,
    $navbarHeight1,
    $navbarHeight2,
    $navbarHeight3,
    $navbarHeight4,
    $navbarHeight5,
    $navbarHeight6
   );
  }

  &.layout-mobile-sidebar-active {
   & .navbar__right--title.typo__title--2 {
    transform: translateX(calc(#{$layoutMenuWidth - 40px}));
    // margin-left: 100px;
   }
   .layout-sidebar {
    left: -0;
   }

   .layout-topbar {
    left: $layoutMenuWidth;
   }
   .layout-menu-appear {
    display: none;
   }

   .layout-mask {
    display: block;
   }
   .layout-main,
   .layout-footer {
    margin-left: $layoutMenuWidth;
   }
  }
 }

 //.body-overflow-hidden {
 //    overflow: hidden;
 //}
}

@media (min-width: $responsiveMobileScreenWidth) {
 .col-md-fixed.w-100 {
  width: auto !important;
 }
}

@media (max-width: 786px) {
 &.layout-topbar-icons-responsive {
  display: block !important;
 }
 &.layout-topbar-icons {
  display: none !important;
 }
}
@media (min-width: 787px) {
 &.layout-topbar-icons-responsive {
  display: none !important;
 }
 &.layout-topbar-icons {
  display: flex !important;
 }
}
.p-treenode-content.p-treenode-selectable,
.sticky-header-100 > div:first-child {
 @extend .typo__title--3;
}
.label {
 @extend .typo__body-text--1;
}
.p-treetable-tbody > tr > td {
 @extend .typo__body-text--2;
}

.responsive-text {
 @media (min-width: 1023px) {
  font-size: 100% !important;
 }
 @include responsive(768px, 1023px) {
  font-size: 95% !important;

  & .p-tree-toggler-icon {
   font-size: 85% !important;
  }
 }

 @include responsive(481px, 767px) {
  font-size: 90% !important;

  & .p-tree-toggler-icon {
   font-size: 85% !important;
  }
 }

 @include responsive(0px, 480px) {
  font-size: 80% !important;

  & .p-tree-toggler-icon {
   font-size: 80% !important;
  }

  .body-overflow-hidden {
   overflow: hidden;
  }
 }
}

.modal-dialog:has(.switch-account-dialog) {
 min-height: 400px !important;
}

@media only screen and (min-width: 575px) {
 .modal-dialog {
  max-width: 90%;
 }
}
@media only screen and (min-width: 768px) {
 .modal-dialog {
  max-width: 85%;
 }
}
@media only screen and (min-width: 1080px) {
 .modal-dialog {
  max-width: 80%;
 }
}
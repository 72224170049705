//Bo sung style cho split Panel
.SplitPane {
    height: auto;
    position: relative !important;
    overflow: auto !important;

    > .vertical {
        overflow: scroll;
        @include Clearfix(auto);
    }
    > .horizontal {
        overflow: scroll;
        @include Clearfix(auto);
    }
    .Pane {
        min-width: 3px;
        min-height: 3px;
    }
}

// hide scrollbar while sthill be able to scroll, split pane
// co the apply cho nhieu man
.SplitPane,
.p-paginator-pages {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
}

.split-pane-without-footer {
    @include combineBreakPointUnit(
        height,
        ' !important',
        $screenHeightWithoutFooter,
        $screenHeightWithoutFooter,
        $screenHeightWithoutFooter,
        $screenHeightWithoutFooter,
        $screenHeightWithoutFooter,
        $screenHeightWithoutFooter
    );
    overflow: auto;
}

.split-pane {
    // height: $screenHeight1 !important; // thường là độ dài của các màn
    // @include combineBreakPointUnit(
    //     height,
    //     ' !important',
    //     $screenHeight1,
    //     $screenHeight2,
    //     $screenHeight3,
    //     $screenHeight4,
    //     $screenHeight5,
    //     $screenHeight6
    // );
    // min-height: $screenMinHeight !important;
    overflow: auto;
}

/* Pane start */

.horizontal,
.vertical {
    & > *:not(:has(.Pane):has(.Resizer)):not(.Resizer) {
        background-color: $bgWhite !important;
        border-radius: $panelBorderRadius;
    }
}

.Pane:has(.p-datatable) {
    min-height: 250px !important;
}

// .Pane.horizontal:has(:not(.SplitPane)):has(.p-accordion) {
.Pane.horizontal:has(> .p-accordion) {
    height: auto !important;
    min-height: 0 !important;
}

/* Pane end */

/* resizable split pane */
.Resizer {
    background: $resizerColor !important;
    background-color: $resizerColor !important;
    border: 1px solid $resizerColor;
    opacity: $resizerOpacityNormal;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
    margin: $resizerMarginSize !important;
    &:hover {
        -webkit-transition: all 200ms ease;
        transition: all 200ms ease;
    }
    &.horizontal {
        cursor: row-resize;
        min-height: $resizerSize !important;
        :hover {
            height: $resizerSize !important;
            opacity: $resizerOpacityHovered;
        }
    }
    &.vertical {
        cursor: col-resize;
        min-width: $resizerSize !important;
        :hover {
            width: $resizerSize !important;
            opacity: $resizerOpacityHovered;
        }
    }
    &.disabled {
        cursor: not-allowed;
        &:hover {
            border-color: transparent;
        }
    }
}

@mixin Horizontal {
    display: flex;
    background: $checkedBorder !important;
    width: 100%;
    cursor: row-resize;
    min-height: $resizerSize !important;
    :hover {
        height: $resizerSize !important;
        opacity: $resizerOpacityHovered;
    }
}

/* resizable split pane  end */
@include responsive(0px, $responsiveMobileScreenWidth) {
    // SplitPane mobile version
    .SplitPane:not(.flex-100):has(> .vertical) {
        display: flex !important;
        flex-direction: column !important;
        overflow: auto !important;
        .Resizer.vertical {
            @include Horizontal;
            margin: 0 !important;
        }
        .Pane.vertical {
            display: flex !important;
            width: 100% !important;
            // overflow: auto !important;
            @include Clearfix(auto);
            &:first-child {
                max-height: 30vh !important;
            }
            .p-card-body {
                // overflow: auto !important;
                @include Clearfix(auto);
            }
        }
    }
    // userinfo table mobile version
    .userinfo-leftpanel.row {
        & > *:not(:first-child) {
            border: none !important;
        }
    }
}

.userinfo-leftpanel.row {
    & > *:not(:first-child) {
        border-left: 2px solid $borderColor;
        margin-bottom: 0px;
    }
}

/* userinfo start */
.userinfo-leftpanel {
    border-radius: 12px;
    overflow: hidden;
    background-color: $color-background;
    margin-bottom: 0px;
    li.ant-menu-item {
        background-color: unset !important;
        display: block;
        padding-left: 0px !important;
        span {
            transition: all 0.5s;
            font-weight: bold;
            font-size: 1.2em;
            padding: 10px 20px;
            border-radius: 20px;
            color: $menuitemColor;
            @include responsive(768px, 1023px) {
                font-size: 100%;
            }

            @include responsive(481px, 767px) {
                font-size: 90%;
            }

            @include responsive(0px, 480px) {
                font-size: 80%;
            }
        }
        &:hover {
            span {
                background-color: $bgTagBlueHover;
                color: $textTagBlue;
            }
        }
        &:focus {
            // background-color: unset;
            span {
                background-color: $bgTagBlue;
                color: $textTagBlue;
            }
        }
        &::after {
            content: unset;
        }
    }

    ul {
        border-right: 0;
        overflow: unset;
        &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: unset;
            span {
                background-color: $bgTagBlue;
                color: $textTagBlue;
            }
        }
    }
}

/* userinfo end */

/* Card start */

body .p-card {
    border-radius: $borderRadius;
    box-shadow: none;
}

.p-card-header {
    padding: $stickyHeaderPadding;
    border: 0 none !important;
    border-bottom: 1px solid $borderColor !important;
    border-radius: $borderRadius $borderRadius 0px 0px;
    @extend .typo__heading--4;
    * {
        margin-top: auto;
        margin-bottom: auto;
        @extend .typo__heading--4;
    }
}

//

.p-card {
    & > .p-card-body {
        // height: 100% !important;
        // overflow: hidden !important;
        @include Clearfix(hidden);
        padding: $cardBodyPadding;
        &:has(.p-datatable, .p-treetable) {
            padding-bottom: 0;
            padding-top: 0;
        }
    }
}

/* Card end */

/* Accordion start */

body .p-accordion-tab:has(> .p-accordion-header.p-highlight) {
    // border-radius: $borderRadius;
    > .p-accordion-header > a,
    > div > .p-accordion-content {
        box-shadow: inset $boxShadowHighlightLight;
        .p-card {
            background: unset !important;
        }
    }
    > .p-accordion-header > a {
        box-shadow: $boxShadowHighlightAccordion;
    }
}

body .p-accordion-header {
    margin-top: 0 !important;
    & > a[aria-expanded='false'] {
        background-color: $color-background !important;
        border: none !important;
    }
    & > a[aria-expanded='true'] {
        background-color: $color-background !important;
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom: 1px solid $borderColor !important;

        span {
            color: #848484 !important;
        }
    }

    & > a {
        border-radius: $borderRadius !important;
        border: none !important;
        // padding: 12.5px 10px !important;

        & .p-accordion-header-text > * {
            margin-left: $inputPaddingX;
            margin-top: auto;
            margin-bottom: auto;

            @extend .limit-word-lines;
            -webkit-line-clamp: 1;

            @extend .typo__title--3;
            // @extend .responsive-text;
        }

        & h4 {
            margin: 0 !important;
            @extend .typo__heading--4;
        }
    }

    &:not(.p-disabled) a:focus {
        box-shadow: none !important;
    }
}

body .p-accordion-tab {
    margin-bottom: 0 !important;
    & .p-accordion-content {
        padding: 0px;
        // padding-top: $paddingSize;
        border-bottom-right-radius: $borderRadius !important;
        border-bottom-left-radius: $borderRadius !important;
        border: none !important;
    }
}

/* Accordion end */

/* Tabview start */

body .p-tabview .p-tabview-panels {
    border: 1px solid $borderColor;
    border-radius: 0;
}

.p-tabview .p-tabview-nav li a {
    padding: 0.5em 1em !important;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a {
    background-color: $color-background;
    margin: 0 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: transparent;
}

.modal-dialog:has(.p-tabview.p-tabview-top) {
    ul.p-tabview-nav {
        & > li:first-child a {
            border-top-left-radius: 0;
        }
        & > li:last-child a {
            border-top-right-radius: 0;
        }
    }
}

body .p-tabview.p-tabview-top {
    ul.p-tabview-nav {
        margin: 0 !important;

        li.p-highlight a {
            &:hover {
                background-color: $highlightColor !important;
                a {
                }
            }

            background-color: unset !important;
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
            border-bottom-width: 2.5px !important;

            span {
                color: $info-primary-color;
            }
        }
        li {
            &:hover {
                a {
                    background-color: $highlightColor;
                    span {
                        color: #000;
                    }
                }
            }
            margin: 0;
            a {
                padding: 5px 9px 7px 9px!important;
                span {
                    @extend .typo__button-text--2;
                    color: $secondary-text-color;
                }

                &:focus {
                    outline: none !important;
                    box-shadow: none !important;
                }
            }
        }

        & > li:first-child a {
            border-top-left-radius: $borderRadius;
        }
        & > li:last-child a {
            border-top-right-radius: $borderRadius;
        }
    }
}

body .p-breadcrumb {
    margin-left: 1.2rem;
    border: none;
    background-color: $backGroupColor;
    @extend .typo__button-text--2;
    color: $primary-text-color;
    .p-menuitem-text {
        @extend .typo__button-text--2;
        color: $primary-text-color;
    }

    .p-breadcrumb-chevron {
        font-size: 90%;
    }
}

body .p-breadcrumb {
    padding: 2px 0;
}

body .p-breadcrumb ul {
    @extend .flex-100-row;
    align-items: center;
    li {
        @extend .center-row-pi;
        height: 100%;
        > .p-menuitem-link {
            @extend .flex-100;
            color: $typography-text;
            font-size: 14px;
            font-weight: 500;
        }
    }
}

/* Tabview end */

/* etc */

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-md-fixed {
    position: relative;
    padding-right: $inputPaddingX;
    padding-left: $inputPaddingX;
    @media (max-width: $responsiveMobileScreenWidth) {
        width: 100%;
        max-width: 100%;
    }
}

.col-md-fixed {
    position: relative;
}

body .p-multiselect-panel {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
}

/* unset highlight panel */

.p-accordion-header,
.p-tabview-selected,
:has(> .p-highlight) {
    background-color: unset !important;
}

// Popup
body #messageBox.modal-dialog {
    @extend .center-column-x;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: unset;
    flex-grow: 1;
    height: auto !important;
    max-height: $mesageBoxMaxHeight;
    top: 30%;
    width: max-content;
    // max-width: unset !important;
    .p-datatable {
        max-width: $messageBoxTableWidth;
    }
    & .modal-content {
        @extend .flex-100;
        min-width: $messageBoxMinWidth;
        overflow: unset;
        width: max-content;
        max-width: 100%;
        flex: auto;
        align-items: center;
        justify-content: center;
        position: relative;
        text-align: center;
        padding: $cardBodyPadding;
        border: none !important;
        border-radius: $borderRadius;
        box-shadow: -1px 1px 11px 3px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: -1px 1px 11px 3px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: -1px 1px 11px 3px rgba(0, 0, 0, 0.2);
        & > * {
            // width: 100%;
            width: -webkit-fill-available;
        }

        & .modal {
            &-title {
                @extend .typo__title--2;
            }

            &-header {
                box-shadow: unset !important;
                display: unset !important;
                @extend .typo__heading--4;
                padding: 8px 0;
                background-color: unset !important;
                color: $error-primary-color !important;
                border: none !important;
                flex-shrink: 0;

                button {
                    margin: 0;
                    position: absolute;
                    top: -10%;
                    border-radius: 100%;
                    left: 0;
                    padding: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 25px;
                    height: 25px;
                    background-color: $color-background;
                    opacity: 1;
                    @include centerFlex;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    span {
                        font-weight: 100;
                        scale: 1.2;
                    }

                    &:focus {
                        span {
                            outline: none !important;
                            border: none;
                            box-shadow: unset;
                        }
                    }
                }
            }

            &-body {
                @extend .flex-100;
                flex: auto;
                flex-shrink: 1;
                flex-grow: 0;
                @extend .typo__body-text--2;
                margin-bottom: 10px;
            }

            &-footer {
                @extend .flex-100;
                border: 0px;
                justify-content: space-around;
                padding: 0;
                flex-shrink: 0;
                padding-top: $inputFieldPaddingY;

                & div:has(> .btn) {
                    margin: 0;
                    border: none;
                    flex-grow: 1;
                    flex-basis: 0;
                    margin-left: $inputFieldPaddingY !important;
                    > .btn {
                        border-radius: $borderRadius;
                        margin: 0;
                        @extend .typo__button-text--2;
                        color: $grey-1-background-color;
                        padding: $cardBodyPadding;
                        border: none;
                        width: 100%;
                    }
                    &:first-child {
                        margin-left: 0 !important;
                        > .btn {
                            background-color: $info-primary-color;
                            color: $grey-1-background-color;
                            border-color: $info-primary-color;
                        }
                    }
                    &:not(:first-child) {
                        > .btn {
                            background-color: $grey-3-background-color;
                            color: $primary-text-color;
                            border-color: $grey-3-background-color;

                            &:hover {
                                background-color: $disable-background-color !important;
                                color: $grey-9-background-color !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

body .modal-danger {
    & h5.modal-title {
    }

    & .modal {
        &-footer {
            & div:has(> .btn) {
                &:first-child {
                    > .btn {
                        background-color: $error-primary-color !important;
                        border-color: $error-primary-color !important;
                        color: white !important;
                        &:focus {
                            box-shadow: 0 0 0 0.2rem $error-primary-color !important;
                        }
                    }
                    &:not(:first-child) {
                    }
                }
            }
        }
    }
}

body .modal-success {
    & h5.modal-title {
    }

    & .modal {
        &-footer {
            & div:has(> .btn) {
                &:first-child {
                    > .btn {
                        background-color: $success-primary-color !important;
                        border-color: $success-primary-color !important;
                        color: $bgWhite !important;

                        &:focus {
                            box-shadow: $dialogBoxShadow $success-primary-color !important;
                        }
                        &:hover {
                        }
                    }
                }
                &:not(:first-child) {
                }
            }
        }
    }
}

.Resizer.vertical {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

body .modal-warning {
    & h5.modal-title {
    }

    & .modal {
        &-footer {
            & div:has(> .btn) {
                &:first-child {
                    > .btn {
                        background-color: $dialogWarningBgColor !important;
                        border-color: $dialogWarningBgColor !important;
                        color: $bgWhite !important;
                        &:hover {
                        }
                        &:focus {
                            box-shadow: $dialogBoxShadow $dialogWarningBgColor !important;
                        }
                    }
                }
                &:not(:first-child) {
                }
            }
        }
    }
}

#navbar-new-ver {
  display: flex;
  justify-content: space-between !important;
  width: 100% !important;
  background-color: initial !important;
  border-bottom: 1px solid #0000000f;

  .navbar__left {
    margin: initial !important;
    position: relative;
    padding-left: 17px;
    min-width: 140px;

    .ant-divider-vertical {
      height: 100%;
      margin: 0 6px 0 16px;
    }
    .box-custom-menu {
      position: absolute;
    }
  }

  .box-btn-dropdown {
    button {
      background-color: initial;
      border: 0px;
      box-shadow: none !important;
      height: fit-content;
    }

    .ant-menu {
      background-color: initial !important;
      padding-left: 40px;

      .ant-menu-submenu-arrow {
        display: none;
      }
    }
  }

  @media (max-width: 991.98px) {
    height: 90px;
    .box-center {
      display: none;
    }
  }
}

.ant-dropdown {
  left: 20px !important;
  top: 70px !important;
  .ant-dropdown-menu {
    border-radius: 12px;
    border: 1px solid #e4e7ec;
    padding: 0;

    li {
      border-bottom: 1px solid #e4e7ec;
    }
    li:first-child:hover {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    li:last-child {
      border: 0px;
      &:hover {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }

  .box-item-nav {
    display: flex;
    align-items: center;
    width: 280px;
    justify-content: space-between;
    padding: 11px;

    .box-image {
      width: 80px;

      img {
        width: 100%;
        height: initial;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .navbar__left {
    .btn-dropdown {
      display: none;
    }
    .ant-divider {
      display: none;
    }
  }
}

@import 'layout/scssVariables/_variables.scss';
@import 'layout/sass/_layout.scss';

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker-input,
:not(textarea).c_input-text,
.c_datepicker,
.p-inputtext:not(label),
.p-dropdown,
.c_input-number input,
.ant-select-selection {
    // border: 1px solid $borderColor !important;
    height: $inputFieldHeight;
    @extend .typo__placeholder--2;
}

.ant-tree-select {
    min-height: $inputFieldHeight;
    > .ant-select-selector {
        min-height: $inputFieldHeight;
    }
}

.c_input-text,
.c_input-number input,
.p-inputtext:not(label),
.ant-select-selection {
    background: $color-background;
    color: $input-color;
    border: 1px solid $borderColor;
    border-radius: $borderRadius !important;
    @extend .center-row-y;
    @extend .typo__label--4;
}

.row:has([class*='col-']) {
    [class*='col-']:has(
            > label,
            > input,
            > textarea,
            .ant-picker,
            .ant-select,
            .p-checkbox,
            .p-dropdown,
            > button
        ) {
        // background: red !important;
        // background-color: red !important;
        padding-left: $inputLabelPadding;
        padding-right: $inputLabelPadding;
    }
}

.row:has(> [class*='col-']) {
    // padding-left: 0 !important;
    // padding-right: 0 !important;
    > [class*='col-']:has(> label):not(.row) {
        @extend .label-input-sizing;
        @extend .center-row-y;
    }
    > [class*='col-']:has(
            > input,
            > textarea,
            .ant-picker,
            .ant-select,
            .p-checkbox,
            .c_input-group,
            .p-dropdown,
            > button
        ):not(.row) {
        padding: $inputLabelPadding;
        @extend .label-input-sizing;
        @extend .center-row-y;
    }
}

.row,
.ant-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 1px;
    align-items: center;
    & > [class^='col-']:not(:has(> label)) {
        padding: $inputPadding;
    }
    & > [class^='col-']:has(input):has(label) {
        margin-bottom: 0;
        padding: 0;

        @extend .label-input-sizing;
    }
}

.row:has(> .row) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    > .row {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.c_input-text:disabled,
.c_input-number:disabled input,
.p-inputtext:disabled,
.input-style:disabled,
.ant-select-disabled .ant-select-selector {
    cursor: not-allowed;
    color: $textReadOnly !important;
    background-color: $disableBg !important;
}

.ant-select-disabled .ant-select-selection-search {
    background-color: $transparent !important;
    input {
        background-color: $transparent !important;
    }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// hiển thị thanh scroll
div.DraftEditor-root {
    border: 1px solid $borderColor;
    border-top: none;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    height: $textFieldHeight;
    width: auto;
    overflow-y: auto;
    > .DraftEditor-editorContainer {
        border: 0;
        padding: auto;
        > .public-DraftEditor-content {
            > div div div {
                margin: 0;
                padding: $inputFieldPadding;
            }
        }
    }
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
    height: 100%;
}

.rdw-editor-toolbar {
    margin-bottom: 0px !important;
    border-radius: $borderRadius !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

/* label input start */
.tooltip-validate,
.tooltip-validate-custom {
    position: absolute;
    transform: translate(0, -20%);

    bottom: 75%;
    z-index: 20;
    left: 15px;
    background-color: $error-primary-color;
    color: $typoWhite !important;
    font-family: $default-font !important;
    padding: $inputTooltipPadding;
    border-radius: $inputTooltipBorder;
    box-shadow: $tooltipBoxShadow;
    @extend .typo__caption--2;
    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 25%;
        border-width: $paddingSize;
        border-style: solid;
        border-color: $error-primary-color transparent transparent transparent;
    }
}

.tooltip-validate {
    &:after {
        margin-left: -5px;
    }
}

.tooltip-validate-custom {
    &:after {
    }
}

/* label input start */

.label-input:not([class*='col-']) {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

// .row:has([class^='col-']:has(input)) {
// }

.label-input-sizing {
    @extend .center-row-y;
    div:has(> label:not(.p-inputtext)) {
        padding-left: 0;
        padding-right: $inputLabelPadding;
        // height: $inputLabelHeight !important;
        overflow-x: hidden !important;
        overflow-y: hidden !important;
        margin: auto;
        display: flex !important;
        flex-direction: row !important;
        height: auto !important;

        & label {
            margin-top: auto !important;
            margin-bottom: auto !important;
            height: auto !important;

            @extend .typo__label--4;
            // white-space: nowrap;
        }
    }
}

.label-input {
    @extend .label-input-sizing;
}
/* label input end */

.p-inputtextarea {
    height: 100px;
}

.input-style {
    background-color: $color-background;
    color: $title-color !important;
    border-color: $borderColor !important;
    border-radius: $borderRadius;
    @extend .typo__body-text--2;
    &:disabled {
        background-color: $color-background;
        color: $mainHighLight;
        border-color: $borderColor !important;
        border-radius: $borderRadius;
    }
}

input,
textarea {
    @extend .input-style;
}

body .modal-content {
    .p-spinner-button-icon.pi.pi-caret-up {
        scale: 0.75;
        &::before {
            content: '\e933' !important;
        }
    }
    .p-spinner-button-icon.pi.pi-caret-down {
        scale: 0.75;
        &::before {
            content: '\e930' !important;
        }
    }

    .p-inputgroup {
        border-radius: 8px;

        & > input {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
        }

        & > button {
            border-top-right-radius: 8px !important;
            border-bottom-right-radius: 8px !important;

            & span {
                scale: 0.8;
                color: white;
            }
        }
    }

    button.p-spinner-button.p-spinner-button-up.p-button.p-component {
        border-top-right-radius: 8px;
    }
    button.p-spinner-button.p-spinner-button-down.p-button.p-component {
        border-bottom-right-radius: 8px;
    }
}

td {
    .p-button-text.p-c {
        display: none;
    }
}

body .p-radiobutton-box.p-component {
    border: 3px solid $info-primary-color !important;
    background-color: transparent !important;

    .p-radiobutton-icon.p-c.pi.pi-circle-on {
        background-color: $info-primary-color !important;
    }
}

.flex-nowrap .p-checkbox-label {
    white-space: break-spaces;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
}

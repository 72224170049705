// Overwrite css library
body,
.p-link {
 font-family: $default-font !important;
}

html {
    scroll-behavior: smooth;
}

// CUSTOM ALL SCROLLBAR

/* For Firefox */
// * {
//  scrollbar-color: $scrollBarColor; /* color of the scroll thumb */
//  scrollbar-width: thin; /* width of the entire scrollbar */
// }

// /* For Chrome, Safari, and Edge */
// *::-webkit-scrollbar {
//  width: $scrollbarWidth;
//  height: $scrollbarWidth;
// }
// *::-webkit-scrollbar-thumb {
//  background: $scrollBarColor !important; /* color of the scroll thumb */
//  border-radius: 999999px; /* roundness of the scroll thumb */
//  transition: all 3s ease !important;
//  -webkit-transition: all 3s ease !important;

//  border: 1px solid $scrollBarColor;
//  background-clip: padding-box;
//  -webkit-background-clip: text;
//  opacity: 1;
// }

// *::-webkit-scrollbar-thumb:hover {
//  transition: all 3s ease !important;
//  -webkit-transition: all 3s ease !important;
//  background-color: $scrollBarColorHover !important; /* new color on hover */
// }

::-webkit-scrollbar {
    width: $scrollbarWidth;
     height: $scrollbarWidth;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $scrollBarColor !important; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
    *::-webkit-scrollbar-thumb:hover {
     transition: all 3s ease !important;
     -webkit-transition: all 3s ease !important;
     background-color: $scrollBarColorHover !important; /* new color on hover */
    }

button:disabled {
    cursor: no-drop !important;
}

// html {
//     // This defines what 1rem is
//     font-size: 62.5%;
    

//     @include respond (tab-land) { // < 1200px ?
//         font-size: 56.25%; // 1rem = 9px
//     }
     
//     @include respond (tab-port) { // < 900px
//         font-size: 50%; // 1rem = 8px
//     }

//     @include respond (big-desktop) { // > 1800px
//         font-size: 75%; // 1 rem = 12px
//     }

// }
// Size hiện giờ là {size figma} / 1.6

$borderRadius: 8px;
$stickyHeaderLeftMargin: 2%;
$paddingSize: 5px;
$responsiveMobileScreenWidth: 768px;
$scrollbarWidth: 0.35rem;

//

/* InputLabel */
$inputFieldHeight: 30px; //48/1.6
// $inputFieldPaddingX: 0.375rem;
// $inputFieldPaddingY: 0.75rem;
$inputFieldPaddingX: 0.2rem;
$inputFieldPaddingY: 0.4rem;
$inputFieldPadding: $inputFieldPaddingX $inputFieldPaddingY;
// $inputTooltipPadding: #{$inputFieldPaddingX / 2} #{$inputFieldPaddingY / 2};
$inputLabelHeight: 30px;
$inputLabelPadding: 0.45rem 0.35rem; //20*/1.6 * 2
$inputTooltipPadding: 0.05rem 0.25rem; //5px/1.6 12px/1.6
$tooltipBoxShadow: #999 0.125rem 0.125rem 0.188rem;
$inputTooltipBorder: 4px; //4px/1.6
$checkboxBorder: 2.4px;
$textFieldHeight: 200px;
$inputPaddingX: $inputFieldPaddingX;
$inputPaddingY: $inputFieldPaddingY;
$inputPadding: $inputFieldPaddingX $inputFieldPaddingY;

/* Panel */
$panelBorderRadius: 8px;
$panelPadding: 5px;

/* Screen */
// $screenHeight1: calc(calc(100vh - 2.4rem) - 100px);
// $screenHeight2: calc(calc(100vh - 1.8rem) - 100px);
// $screenHeight3: calc(calc(100vh - 1.8rem) - 110px);
// $screenHeight4: calc(calc(100vh - 1.8rem) - 120px);
// $screenHeight5: calc(calc(100vh - 1.8rem) - 130px);
// $screenHeight6: calc(calc(100vh - 1.8rem) - 150px);

$screenHeight1: calc(calc(100vh - 2.4rem) - 50px);
$screenHeight2: calc(calc(100vh - 1.8rem) - 50px);
$screenHeight3: calc(calc(100vh - 1.8rem) - 55px);
$screenHeight4: calc(calc(100vh - 1.8rem) - 60px);
$screenHeight5: calc(calc(100vh - 1.8rem) - 65px);
$screenHeight6: calc(calc(100vh - 1.8rem) - 75px);

// $screenHeight1: 100vh;
// $screenHeight2: 100vh;
// $screenHeight3: 100vh;
// $screenHeight4: 100vh;
// $screenHeight5: 100vh;
// $screenHeight6: 100vh;

$screenHeightWithoutFooter: calc(calc(100vh) - 80px);
// $screenHeight1: calc(calc(100vh - 2.4rem) - 100px);
// $screenHeight: calc(calc(100vh - 0.9rem) - 79px);
$screenMinHeight: 640px;

/* Resizer */
$resizerMarginSize: 4px;
$resizerSize: 4px;
$resizerBorderRadius: 8px;

/* Tree */
// $treeLabelPaddingX: 10px; // 16/1.6
// $treeLabelPaddingY: 12.5px; // 20/1.6
// $treeLabelPaddingX: calc(#{$inputFieldPaddingX * 2}); // 16/1.6
$treeLabelPaddingY: calc(#{$inputFieldPaddingY * 2}); // 20/1.6
$treeLabelPaddingX: calc(#{$inputFieldPaddingX * 3}); // 16/1.6
// $treeLabelPaddingY: $inputFieldPaddingY/1.275; // 20/1.6
$treeChildrenPadding: 0 0 0 calc(1.125rem);
$treenodeContentPadding: calc(0.75rem);
// $treeTogglerPadding: 0 calc(1.5em) 0 0;
$treeTogglerTransform: translate(-1.5rem, 0); //24px/1.6
$treenodeDotSize: 0.5rem;
$treenodeDotTransform: translate(0, calc(50% - #{$treenodeDotSize / 2}));
$treenodeDotMarginR: 0.572rem;
$treenodeCheckboxMarginR: $treenodeDotMarginR;
$treeFilterPadding: $treenodeContentPadding;
$treeFilterHeight: 40px;

/* Button */
$buttonPadding: 0.312rem 1rem;
$buttonPaddingWIcon: 0.312rem 1rem 0.312rem 2.25rem;

/* Navbar */
$navbarHeight1: 30;
$navbarHeight2: 40;
$navbarHeight3: 50;
$navbarHeight4: 60;
$navbarHeight5: 70;
$navbarHeight6: 80;

$navbarAvatarHeight1: #{$navbarHeight1 / 1.5};
$navbarAvatarHeight2: #{$navbarHeight2 / 1.5};
$navbarAvatarHeight3: #{$navbarHeight3 / 1.5};
$navbarAvatarHeight4: #{$navbarHeight4 / 1.5};
$navbarAvatarHeight5: #{$navbarHeight5 / 1.5};
$navbarAvatarHeight6: #{$navbarHeight6 / 1.5};

/* Layout */
$layoutMenuWidth: 280px;

/* Card */
// $cardBodyPadding: 10px 15px; //16px/1.6  24px/1.6
// $cardBodyPadding: 0.625rem 0.938rem; //16px/1.6  24px/1.6
$cardBodyPadding: $treeLabelPaddingX $treeLabelPaddingY; //16px/1.6  24px/1.6

/* DataTable */
$datatableMinHeight: 200px;
$datatableEmptyImageSize: 120px;
// $datatableFooterGoToPageSize: 100px;
$datatableFooterGoToPageMinSize: 60px;
$datatableTdPadding: 0.5rem 0.6rem; //4px/1.6  12px/1.6

// $datatableScrollHeight: calc(100% - 45px);
// $datatableScrollHeightWithFilter: calc(100% - 70px);
// $datatableTableHeaderHeight: 45px;
// $datatableTableHeaderHeightWithFilter: 70px;

$datatableTableHeaderHeight: auto;
$datatableScrollHeight: calc(100%);

$datatablePaginatorPadding: $cardBodyPadding; //16px/1.6  24px/1.6

$datatablePaginatorHeight: 2em;

/* Dialog */
$dialogMaxHeight: 95vh;
$mesageBoxMaxHeight: 60vh;
$mesageBoxBiggerMaxHeight: 75vh;
$messageBoxMinWidth: 400px;
$messageBoxTableWidth: 40vw;
$dialogMinHeight: 0vh;
$dialogLabelPadding: $cardBodyPadding; //16px/1.6  24px/1.6
$dialogFooterPadding: $cardBodyPadding; //16px/1.6  24px/1.6

/* Other */
$stickyHeaderPadding: $treeLabelPaddingX $treeLabelPaddingY;

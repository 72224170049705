@import 'layout/scssVariables/_variableColor.scss';
@import 'layout/scssVariables/_variableSize.scss';
@import 'layout/scssVariables/_variables.scss';

@import 'layout/sass/_mixins.scss';
@import 'layout/sass/_typo.scss';

.body {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.left,
.right {
    width: 100%;
    // border-radius: $borderRadius;
    // border: 1px solid $borderColor;
}

.container {
    padding: $cardBodyPadding;

    .header {
        display: table-row;
        flex-grow: 0;
        flex-shrink: 1;
        height: 90px;
        align-items: center;
        table-layout: fixed;
    }

    .body {
        padding: 10px;
    }

    .headerRight {
        margin-left: 10px;
        // display: flex;
        display: table-cell;
        height: 60px;
        padding:0px; margin:0px;
        padding-left: 2em;

        flex-direction: column;
        justify-content: space-around;
        flex-grow: 0;
        flex-shrink: 0;
        .description {
            @extend .typo__body-text--2;
        }

        & > * {
            position: relative;
            line-break: strict;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:after {
                content: '';
                position: absolute;
                height: 1px;
                border-radius: 3px;
                width: 100%;
                background-color: #00aeef;
                opacity: 0.1;
                bottom: -2px;
                left: 0;
            }
        }
    }

    .headerLeft {
        width: auto;
        overflow: hidden;
        height: auto;
        // height: 60px;
        // width: 80%;
        // min-width: 30px;
        // flex-grow: 1;
        // flex-shrink: 1;
        // flex-basis: auto;

        // display: flex;
        display: table-cell;

        // width: 0.1%;
        // height: 60px;
        padding:0px; margin:0px;
        white-space: nowrap;
        vertical-align: middle;

        img {
            display: flex;

            align-items: center;
            overflow: hidden;
            height: 90px;
            padding:0px; margin:0px;
            // height: 100%;
            // display: flex;
            // flex-grow: 0;
            // flex-shrink: 1;
        }
    }
}
.right,
.left {
    .list {
        list-style: none;
        margin: 0 !important;
        padding: 1em 0.5em;
        overflow: auto;
        max-height: 80vh !important ;

        .item {
            cursor: pointer;
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
        .selected {
            // border-color: $mainHighLight;
            border-radius: $borderRadius;
            box-shadow: $dialogBoxShadow $mainHighLight !important;
        }
    }
}
.footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    button {
        background-color: $mainHighLight;
        color: white;
        font-weight: 400;
        &:hover {
            background-color: #3ac1f2;
            color: white;
        }
        &:last-child {
            margin-left: 10px;
        }
    }
}

.notification {
    cursor: pointer;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: 0.25s all ease-in-out;
    margin-bottom: 0 !important;
    &:hover {
        background-color: #e1e1e1;
    }

    &_content {
        display: flex;
        flex-direction: column;
        margin: auto 0;

        & > label {
            &:not(:last-child) {
            }
        }

        &__image {
            overflow: hidden;
            border-radius: 50%;
        }

        &__header {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            font-weight: bold;
        }

        &__body {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
        }

        &__footer {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            font-size: 90%;
        }
    }
}

.unseen {
    background-color: #e0f1f7;
}

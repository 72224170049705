@import "../../../layout/scssVariables/_variables.scss";
@import "../../../layout/scssVariables/variableColor";
@import "../../../layout/sass/mixins";
@import "../../../layout/sass/typo";
.listOfSubSystems{
 background-position: center;
 background-size: cover;
 background-repeat: no-repeat;
 background-attachment: fixed;

 .layout-wrapper{
  background-color: initial !important;
 }

 .warp-box-name{
  margin-top: 86px;
  background: linear-gradient(#0066B3E5, #2590C2E5, #38A6CAE5) ;
  height: 217px;
  position: relative;
  padding: 36px 60px;
  display: flex;
  margin: 5.4%;
  border-radius: 15px;
  .box-date-name{
   height: 100%;
   display: flex;
   flex-direction: column;
   .box-date{
    font-size: 24px;
    color: #fff;
   }
   .box-name{
    margin-top: auto;

   h3{
    color: #fff;
    font-size: 44px;
   }
   }
  }
  .box-bg-content{
    .box-bg{
     background-position: center;
     background-size: cover;
     background-repeat: no-repeat;
     width: 385px;
     height: 257px;
     position: absolute;
     right: 5.5%;
     top: -39px;
    }
  }
 }

}
#content-layout {

}

#content-layout > * {
 position: relative;
 z-index: 2;
}

#content-layout {
 height: 100%;
 background-position: center;
 background-size: cover;
 background-repeat: no-repeat;
 background-attachment: fixed;
}

.item-container {
 margin: 0 auto;
 padding-top: 0 !important;
 min-width: 90% !important;
 width: 90% !important;
 display: flex;
 flex-direction: row;
 justify-content: flex-start;
 flex-wrap: wrap;
 gap: 30px;
 .box-item{
  width: calc(100% / 3 - 30px);
  background: linear-gradient(#FFFFFFCF, #FFFFFF8C) ;
  border-radius: 15px;
 }
}

.col-md-3 {
 padding: 12px !important;
 display: table-cell;
 vertical-align: top;
}

.client-app {
 position: relative;
 min-height: auto !important;
 //border: 1px solid $color-background !important;
 border-radius: 10px;
 //background-color: $color-background;
 //margin: 10px 15px 10px 0px;
 //box-shadow:inset 1px 0px 12.5px -6px rgba(0, 0, 0, 0.75);
 //-webkit-box-shadow:inset 1px 0px 12.5px -6px rgba(0, 0, 0, 0.75);
 //-moz-box-shadow:inset 1px 0px 12.5px -6px rgba(0, 0, 0, 0.75);
 /* display: flex;
 flex-direction: column;
 align-items: center; */
 padding: 24px;
 cursor: pointer;
 height: 100%;
 width: 100%;
 z-index: 1000;
 outline-offset: .25rem;
 transition: all .25s;

 &:hover {
    outline: .4rem solid $secondary-color-1;
    //transform: scale(1.05) translateY(-0.25rem);
    box-shadow: 0 2.5rem 4rem rgba(0,0,0,0.25);
    z-index: 20;
 }




}

.img-app-frames {
 width: 100%;
 //background-color: $color-background;
 margin: 10px 0 16px 0;
 display: flex;
 .img-responsive {
  //max-width: 100%;
  //max-height: 100%;
  width: 124px;
  height: 113px;
 }
}

.client-app-item {
 display: flex;
 flex-direction: column;
 height: 140px;
 .client-app-name {
  font-size: 28px;
 }

 .client-app-des {
  color: #A0A1A2;
  font-size: 16px;
 }
}

.name-app {
 padding: 15px 5.5%;
}

.title {
 position: relative;
}
.title:hover {
 text-decoration: underline;
 cursor: pointer;
}
.title::after {
 position: absolute;
 content: "";
 background-color: $color-background;
 height: 75%;
 width: 7px;
 top: 50%;
 transform: translate(-50%, -50%);
 left: -15px;
}
.control {
 height: 120px;
}

.item {
 min-height: 100vh;
}

#content-layout .item .row {
 display: block;
 justify-content: center;
 padding-bottom: 200px;
}

/* Responsive Styles */

@media screen and (max-width: $responsiveMobileScreenWidth) {
 #content-layout{

 }
 .item-container {
  width: 90% !important;
  .box-item{
   width: 100%;
   margin-bottom: 20px;

  }
 }


 .client-app {
  margin: 10px;
  padding: 24px;
 }
 .img-app-frames {
  width: 100%;
  height: auto;
 }
}

.header {
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 0 1rem;
}
.left__pannel {
 height: 100%;
 width: 100px;
 display: flex;
 justify-content: center;
 align-items: center;
}

.left__pannel button {
 width: 100%;
 scale: 1.1;
 background-color: #3dcaf1 !important;
 border-radius: 8px;
 padding: 0.25rem 0.25rem;
 color: $grey-1-background-color !important;
 box-shadow: -3px -2px 40px -11px rgba(36, 36, 36, 0.53);
 -webkit-box-shadow: -3px -2px 40px -11px rgba(36, 36, 36, 0.53);
 -moz-box-shadow: -3px -2px 40px -11px rgba(36, 36, 36, 0.53);
 transition: 0.2s all ease-in-out;
}

.left__pannel button:hover,
.left__pannel button:active {
 width: 100%;
 scale: 1.08;
 background-color: #3dcaf1 !important;
 border-radius: 8px;
 padding: 0.25rem 0.25rem;
 color: $grey-1-background-color !important;
 box-shadow: -3px -2px 40px -6px rgb(54, 54, 54);
 -webkit-box-shadow: -3px -2px 40px -6px rgb(56, 56, 56);
 -moz-box-shadow: -3px -2px 40px -6px rgb(68, 68, 68);
}

.listOfSubSystems .navbar__left {
 width: auto;
//  max-width: 30%;
 justify-content: start;
 margin: auto;
 border: none;
}

a:has(>.navbar__left):not(.active) {
   height: 100%;
    width: 250px;
    max-width: 30vw;
    //border-right: 1px solid #e8e8e8;
    display: flex;
}

.listOfSubSystems .navbar__left svg {
 width: 100%;
}
.fixed-children {
   .layout-footer {
      background-position: bottom right;
      z-index: 10000000;
   }
}

/* TreeTable start */
.left-pane-fullscreen {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex !important;
    flex-direction: column;
    padding: 0 !important;

    .p-card-body {
        display: flex;
        flex-direction: column;
        height: 100% !important;
        padding: 0 !important;
    }
    .p-card-header {
        height: 30px !important;
    }
    .p-card-content {
        display: flex !important;
        flex-direction: column;
        height: 100% !important;
        & > div:first-child {
            padding: $treeLabelPaddingX $treeLabelPaddingY;
            @extend .typo__title--3;
        }
    }
    .p-tree-selectable {
        height: 100% !important;
    }
    .ant-select-selector {
        // padding: $treeFilterPadding !important;
        border: 1px solid $borderColor !important;
        border-radius: 0 !important;
        height: $treeFilterHeight !important;
        input {
            // border-width: 1px 0 !important;
            padding: 0 !important;
        }
    }
}

.read-only {
    & > .p-treetable {
        & > .p-treetable-tablewrapper {
            & > table {
                & > .p-treetable-tbody {
                    .p-treetable-checkbox {
                        @extend .disabled;
                    }
                }
            }
        }
    }
}
.p-treetable {
    height: 100% !important;
    max-height: 100% !important;
    min-height: 100px !important;
    overflow: auto;
    display: flex !important;
    flex-direction: column !important;

    & > .p-treetable-tablewrapper {
        height: 100% !important;
        max-height: 100% !important;
        overflow: unset;
        display: flex !important;
        flex-direction: column !important;
        & > table {
            min-height: 100% !important;
            max-height: 100% !important;
            & > .p-treetable-thead {
                position: sticky;
                position: -webkit-sticky;
                top: 0;
                z-index: 10;

                flex-shrink: 0;
                // height: 100% !important;
                // max-height: 100% !important;
            }
            & > .p-treetable-tbody {
                height: 100% !important;
                max-height: 100% !important;
                > tr {
                    &.isDefault,
                    &.prevent {
                        .p-treetable-checkbox {
                            pointer-events: none;
                            .p-checkbox-box {
                                @extend .p-checkbox-box-disabled;
                            }
                        }
                    }
                }
                td {
                    @extend .center-row-pi;
                    .p-treetable-toggler {
                        height: 100% !important;
                    }
                    .p-treenode-icon,
                    .p-treetable-toggler {
                        height: auto;
                        width: auto;
                        > .p-treetable-toggler-icon {
                            -webkit-transform: $treeTogglerTransform;
                            transform: $treeTogglerTransform;
                            color: $typography-title !important;
                            // padding: $treeTogglerPadding;
                        }
                    }
                }
            }
        }
    }
}

/* TreeTable end */

/* Tree start */

// Tree
[class*='p-treetable-toggler-icon'],
[class*='p-tree-toggler-icon'] {
    @extend .center-row-y;
    height: 100% !important;
    &::before {
        @extend .center-row-y;
        height: 100% !important;
    }
}

span.p-tree-toggler.p-unselectable-text.p-link {
    position: absolute;
    right: 2px;
    // top: 50%;
    // transform: translate(0, -50%);
    text-align: center;
}

.p-treenode-content[aria-expanded='true'] {
    color: #000 !important;

    & .p-treenode-icon {
        color: #000 !important;
    }
}

// Tree

.p-tree {
    .p-tree-loading-mask {
        z-index: 1000009000000000 !important;
        opacity: .2;
    }
}

.p-treenode-content.p-treenode-selectable {
    transition: all;
    position: relative;
    width: 100% !important;
    font-size: 110%;
    font-weight: 500;
    color: $typoSecondaryText !important;

    .p-treenode-icon {
        padding-left: 0 !important;
        &::before {
            height: 0 !important;
            width: auto !important;
        }
    }

    & > *:nth-child(2) {
        &:not(.p-checkbox):not(.p-treenode-icon) {
            &::before {
                content: '';
                height: $treenodeDotSize;
                width: $treenodeDotSize;
                background-color: $disableCheckedBg;
                border-radius: 50%;
                transform: $treenodeDotTransform;
                display: inline-block;
                left: 10px;
                margin-right: $treenodeDotMarginR;
            }
        }
    }
}

.empty-tree {
    height: 0px !important;
    padding: 0px !important;
    width: 0px !important;
    display: none !important;
}

li.p-treenode {
    padding: 0 !important;
}

li.p-treenode:not(:last-child):not(.p-treenode-leaf) {
    border-bottom: 1px solid $bottomBorder;
}

.p-tree {
    border: 1px solid $borderColor !important;
    height: 100% !important;
    max-height: 100% !important;
    overflow: hidden;
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    border-radius: 0 !important;
    width: 100% !important;
    .p-inputtext {
        border: 1px solid $borderColor;
        border-radius: 0px;
    }
    .p-tree-filter-container {
        padding: 0 !important;
        border-width: 0 !important;
        input {
            padding: $treeFilterPadding !important;
            border-radius: 0px !important;
            border-top-width: 0 !important;
            height: $treeFilterHeight;
            &::-webkit-input-placeholder::before {
                content: 'search...';
            }
        }
    }
    > .p-tree-container {
        // display: inline-table !important;
        height: 100% !important;
        max-height: 100% !important;
        // width: unset !important;
        flex-grow: 1 !important;

        width: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        overflow: auto;
        padding: 0 !important;
        .p-treenode {
            width: unset !important;
            background-color: $color-background;
            > .p-treenode-content {
                padding-top: $treenodeContentPadding !important;
                padding-bottom: $treenodeContentPadding !important;
                @extend .center-row-pi;
                flex-direction: row !important;
                width: 100% !important;
                display: flex !important;
                word-break: break-all;
                background-color: inherit;
                &:focus {
                    box-shadow: none !important;
                }
                > .p-treenode-label {
                    margin-left: 4px !important;
                    word-break: break-all;
                    width: 100% !important;
                    overflow: hidden !important;
                    @extend .center-row-y;
                    > span {
                        width: auto !important;
                        display: inline-block;
                    }
                }
                > .p-tree-toggler {
                    right: 0;
                    left: 100%;

                    position: -webkit-sticky !important;
                    position: sticky !important;
                    height: 100% !important;
                    width: 0 !important;
                }
                > .p-treenode-icon,
                > .p-tree-toggler {
                    height: auto;
                    width: auto;
                    background-color: inherit;
                    @extend .center-row-y;
                    > .p-tree-toggler-icon {
                        -webkit-transform: $treeTogglerTransform;
                        transform: $treeTogglerTransform;
                        font-size: 100%;
                        background-color: inherit;
                        // padding: $treeTogglerPadding;
                    }
                }
                > .p-treenode-icon {
                    // position: absolute;
                    left: 0% !important;
                    @extend .center-row-y;
                }
                > .p-checkbox {
                    margin-right: $treenodeCheckboxMarginR !important;
                    @extend .center-row-y;
                }
                &:has(> .p-checkbox) {
                    // background-color: red !important;
                    pointer-events: none;
                    > .p-checkbox,
                    .p-tree-toggler {
                        pointer-events: all !important;
                    }
                }
            }
            > .p-treenode-content:has(> .p-tree-toggler) {
                @include combineBreakPointUnit(
                    padding-left, px ,
                    15px,
                    17.5px,
                    20px,
                    22.5px,
                    25px,
                    30px
                );
                @include combineBreakPointUnit(
                    padding-right, px ,
                    15px,
                    17.5px,
                    20px,
                    22.5px,
                    25px,
                    30px
                );
                > .p-treenode-label {
                    @include combineBreakPointUnit(
                        width,
                        !important,
                        calc(100% - 30px),
                        calc(100% - 35px),
                        calc(100% - 40px),
                        calc(100% - 45px),
                        calc(100% - 50px),
                        calc(100% - 60px)
                    );
                    // width: calc(100% - 60px) !important;
                }
            }
            .p-treenode-children {
                // display: inline-table !important;
                flex-direction: column !important;
                // width: unset !important;

                width: 100% !important;
                display: flex !important;
                padding: $treeChildrenPadding !important;
                > .p-treenode-leaf {
                    .p-tree-toggler {
                        display: none !important;
                    }
                }
            }
            // &>:not(span) {

            // }
            &:not(:has(> .p-treenode-content > .p-treenode-label > span)) {
                > .p-treenode-content {
                    @extend .empty-tree;
                    > .p-treenode-label {
                        @extend .empty-tree;
                        &::before {
                            @extend .empty-tree;
                        }
                    }
                    > .p-tree-toggler {
                        @extend .empty-tree;
                        > .p-tree-toggler-icon {
                            @extend .empty-tree;
                            &::before {
                                @extend .empty-tree;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Tree end */

.tree-viewer {
    @include customTableStyles(16, 1);
    @include customTableStyles(0, 0);
    @include customTableStyles(32, 2);
    @include customTableStyles(48, 3);
}

.treeListGroup {
    .p-highlight {
        // background-color: #f3f2f2 !important;
        // color: $menuitemDarkActiveColor !important;

        .p-treenode-icon {
            //  color: $menuitemDarkActiveColor !important;
            * {
                &::before {
                    //  color: $menuitemDarkActiveColor !important;
                }
            }
        }

        .p-checkbox .p-checkbox-box {
            //  border-color: $menuitemDarkActiveColor !important;
        }
    }

    .p-tree-toggler {
        // color: $menuitemDarkActiveColor !important;
    }
}

body .p-tree .p-treenode .p-treenode-content {
    width: fit-content;
    border-radius: 1px !important;
    z-index: 0;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    // box-shadow: 0 0 0 0.1em #8dcdff !important;
    z-index: 10;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content:hover {
    background-color: $backGroupColor !important;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    font-weight: bold;
    background-color: rgb(222, 239, 253) !important;

    .p-treenode-label,
    .p-tree-toggler,
    .p-treenode-icon {
        color: $mainHighLight !important;
        * {
            &::before {
                color: $mainHighLight !important;
            }
        }
    }
    .p-checkbox {
        .p-checkbox-box {
            border-color: $borderColor;
            &.p-highlight {
                border-color: $checkedBorder;
            }
        }
    }
}

.p-treenode-content.p-treenode-selectable:has(span[data-status='0']) {
    background-color: $disableTree;
}
.p-treenode.p-treenode-leaf:has(span[data-not-allow='2']) {
    background-color: $disableTree;
    background-color: $disableTree !important;
    pointer-events: none !important;
    cursor: no-drop;
    .p-checkbox {
        @extend .p-checkbox-box-disabled;
        // visibility: hidden !important;
    }
    td {
        border-radius: 0px !important;
    }
}
.p-tree tr:has(td span[data-not-allow='2']) {
}
.panel-user-group tr:has(td span[filter-status='0']) {
    background-color: $disableTree !important;
    pointer-events: none;
    display: none;
    cursor: no-drop;
    td {
        border-radius: 0px !important;
    }
}
.panel-user-group.switchOn tr:has(td span[switch-status='0']) {
    background-color: $disableTree !important;
    pointer-events: none;
    display: none;
    cursor: no-drop;
    td {
        border-radius: 0px !important;
    }
}

.p-tree {
    * {
        -webkit-transition: all 0.3s ease !important;
        transition: all 0.3s ease !important;
    }
}


.p-tree-toggler,
.p-tree-toggler-icon,
.p-accordion-toggle-icon,
[class*='p-treetable-toggler-icon'],
.p-row-toggler-icon {
 -webkit-transition: all 0.0s ease !important;
 transition: all 0.0s ease !important;
 &::before {
   -webkit-transition: all 0.0s ease !important;
   transition: all 0.0s ease !important;
 }
}
@import "layout/scssVariables/_variables.scss";

.c_input-group {
    display: flex;

    .c_input-text {
        font-size: 14px !important;
        background: $color-background !important;
        color: $input-color !important;
        border: 1px solid $borderColor !important;
        // border-radius: 8px !important;
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        height: $inputFieldHeight;
    }

    .c_input-text:disabled input {
        cursor: not-allowed;
    }

    button {
        border: 1px solid $borderColor !important;
        border-left: none !important;
        background-color: $color-background !important;
        color: $input-color !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }

    button:hover {
        background-color: $color-background !important;
        border: 1px solid $borderColor !important;
        border-left: none !important;
    }
}

@import 'layout/sass/_mixins.scss';
@import 'layout/scssVariables/_variableColor.scss';
@import 'layout/scssVariables/_variableSize.scss';

.user-info-border .p-datatable-thead tr th {
    border: 1px solid #c8c8c8 !important;
}
.user-info-border .p-datatable-tbody tr td {
    border: 1px solid #c8c8c8 !important;
    border-top: none !important;
}
.selectedOptionExists {
    margin: 10px;
    padding: 5px;
    color: red;
}
#split-pane {
    .modal-header {
        border-bottom: none;
    }

    & > .modal-body {
        padding: 20px;
        background-color: #f7f6f6;
    }

    .p-card-header {
        border-bottom: none !important;
        padding: 5px 1.5em;
    }

    .p-card-content {
        & > .p-card-content {
            .modal-body {
                padding: 1.25em 1.5em;
            }
        }
    }
}

.p-card.flex-100 .p-card-body {
    height: 100% !important;
    .p-card-content {
        height: 100% !important;
    }
}

.row > *:first-child {
    align-self: flex-start;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    display: flex;
    align-self: center;
}

.modal-footer {
    border-top: 0;
    & > *:not(:last-child):hover {
        margin-right: 0.25rem;
    }

    & > *:not(:first-child):hover {
        margin-left: 0.25rem;
    }
}

#profile {
    font-size: 110%;
    width: 100%;
    #profile__container {
        display: flex;
    }

    #profile__body {
        min-height: unset;
    }
    img {
    }
    & > * {
        padding: 0px 0 20px 0;
    }
}

.input_label:disabled {
    background-color: transparent !important;
    border-top: 0px solid transparent !important;
    border-left: 0px solid transparent !important;
    border-right: 0px solid transparent !important;
    border-radius: 0px !important;
    padding-bottom: 3px !important;
    padding-left: 0px;
}

.checkbox {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    scale: 0.9;
    cursor: pointer;
    right: 0;
}

.hidden {
    max-width: 0;
    overflow: hidden;
    padding: 0 !important;
}

#oldPassword,
#newPassword,
#confirmPass {
    // border-radius: 6px;
    // border: 1.25px solid #e4e7ea;
}

#OTP {
    // border-radius: 6px !important;
    // border: 1px solid #e4e7ea !important;
}

// .login-session .p-datatable-scrollable-body {
//     min-height: calc(100vh - 350px) !important;
//   }

#btn-otp {
    > span {
        display: inline-block;
    }
}

.alias_active {
    // box-shadow: inset $boxShadowHighlightLight;
    // background: #9ec9fa4d !important
    outline: $checkboxBorder solid $borderColorHover2;
}
@import 'layout/scssVariables/_variables.scss';
@import 'layout/sass/_layout.scss';

.select_t {
    .ant-select-selection-selected-value {
        width: 100%;
    }
}

.optionSelectItem,
.ant-select-selector {
    width: 100%;
    border-bottom: 1px solid $borderColor;
    border-left: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    // height: 0;
    @extend .center-row-y;

    .p-grid {
        @extend .center-row-y;
    }
}

.itemProp {
    width: 100%;
    flex-grow: 1;
    height: auto;
    @extend .center-row-y;
    .borderRight {
        border-right: 1px solid $borderColor;
    }

    .noWrap {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding: 0;
        padding-left: $inputFieldPaddingX*2;
        padding-right: 0;
        height: auto;
    }

    .cell {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    > * {
        padding: 0;
        padding-left: $inputFieldPaddingX;
        padding-right: $inputFieldPaddingX;
    }
}

.colToggle > .p-multiselect-label-container > label {
    display: none;
}

.expandTreeSelectExpanededDropdown {
    max-width: 100vw !important;
    // width: 150% !important;
    // background-color: red !important;
    // position: sticky !important;
    // position: -webkit-sticky !important;
}

//end styling for split panel
.ant-select {
    &.h-auto,
    &.ant-select-multiple {
        &.height-100px {
            // max-height: 100px;
            > .ant-select-selector {
                max-height: 100px;
            }
        }
        > .ant-select-selector {
            height: auto !important;
            line-height: unset !important;
            padding: 0;
            &:has(.ant-select-selection-overflow-item > .ant-select-selection-item) {
                overflow: auto;
                padding: calc(#{$inputFieldPaddingX / 2}) calc(#{$inputFieldPaddingY / 2});
                padding-right: 24px;
            }
            > .ant-select-selection-overflow {
                > .ant-select-selection-overflow-item {
                    padding: calc(#{$inputFieldPaddingX / 2}) calc(#{$inputFieldPaddingY / 2});
                    > .ant-select-selection-item {
                        margin: 0;
                        > .p-grid {
                            // position: fixed;
                            > div {
                                // background: red !important;
                            }
                        }
                        > .ant-select-selection-item-remove {
                            margin: 0;
                            @extend .flex-100;
                            @extend .center-row-y;
                            > span {
                                @extend .center-row-y;
                            }
                        }
                    }
                    &.ant-select-selection-overflow-item-suffix {
                        display: block;
                    }
                }
            }
            > .ant-select-selection-placeholder {
                right: $inputFieldPaddingY !important;
                left: $inputFieldPaddingY !important;
                // line-height: unset !important;
            }
            &::after {
                margin: 0;
                // line-height: unset;
            }
        }
    }
    > .ant-select-clear {
        z-index: 9999 !important;
    }
}
.ant-select-dropdown {
    @extend .expandTreeSelectExpanededDropdown;
    .ant-select-tree {
        .ant-select-tree-list {
            // @extend .flex-100;
            > .ant-select-tree-list-holder {
                // @extend .flex-100;
                .ant-select-tree-treenode {
                    &:has(> span[title='']) {
                        @extend .empty-tree;
                    }
                }
                > div {
                    // @extend .flex-100;
                    // height: 99999px !important;
                    // overflow: auto !important;
                    > .ant-select-tree-list-holder-inner {
                        // transform: none !important;
                    }
                }
            }
        }
    }
}

.ant-select-dropdown-menu-item {
    position: relative;
    display: block;
    padding: 3px 12px;
    overflow: hidden;
    color: #000 !important;
    font-family: $default-font !important;
    font-size: 13px !important;
    font-weight: normal !important;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    height: 27px;
}

.ant-select-selection__rendered {
    position: relative;
    display: block;
    margin-right: 0.429em;
    margin-left: 0.429em;
    line-height: 27px !important;
}

.ant-select-dropdown-menu-item-selected {
    background-color: $grey-3-background-color !important;
    color: $mainHighLight !important;
    font-weight: bold !important;
}

.ant-select-dropdown-menu-item:hover {
    background-color: $grey-3-background-color;
    color: $mainHighLight !important;
    font-weight: bold !important;
}

.ant-select-selection {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid $borderColor !important;
    border-top-width: 1.02px;
    border-radius: 4px;
    outline: none;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $mainHighLight !important;
    height: $inputFieldHeight;
}

.ant-select-selection--multiple .ant-select-selection__choice {
    position: relative;
    float: left;
    max-width: 99%;
    margin-right: 4px;
    padding: 0 20px 0 10px;
    overflow: hidden;
    color: $mainHighLight !important;
    background-color: $disableBg;
    border: 1px solid $borderColor !important;
    border-radius: 15px !important;
    cursor: default;
    -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-disabled .ant-select-selection {
    color: $mainHighLight !important;
    background-color: $disableBg;
    cursor: auto !important;
    opacity: 0.5 !important;
    font-family: $default-font !important;
    font-size: 13px !important;
}

.ant-select-selection-selected-value {
    float: left;
    max-width: 100%;
    padding-right: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: $default-font !important;
    font-size: 13px;
}

.ant-select-arrow > * {
    line-height: 1;
    color: $mainHighLight !important;
}

.tagSingle {
    ant-select-selection {
        height: 37px !important;
    }

    .ant-select-selection__choice {
        border-style: none !important;
    }
}

.ant-select-selection--multiple {
    height: auto !important;
}

.ant-select-dropdown {
    padding: 0 !important;
}

.ant-select-item {
    // padding: 0px 12px !important;
}

.ant-select-selection-item,
.ant-select-selection-search,
.ant-select-focused,
.ant-select-item-option-selected {
    color: $mainHighLight !important;
}

.ant-select-disabled .ant-select-selector {
    color: $textReadOnly !important;
    background: $disableBg;
    .ant-select-selection-item {
        color: $textReadOnly !important;
        background: $transparent;
    }
}

// Paginator
.ant-select-selector {
    .ant-select-selection-search {
        input {
            height: 100% !important;
            padding: 0 0.4rem !important;
        }
    }
}

.c_select {
    width: 100%;
    .itemProp .borderRight {
        border-right: 1px solid $borderColor;
    }
}

.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
        padding-left: $inputFieldPaddingY;
        padding-right: $inputFieldPaddingY;
        >.ant-select-selection-item{
            display: flow;
        }
    }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid $borderColor;
}

.p-dropdown-item:not(.p-highlight):not(.p-disabled):hover,
.p-dropdown-item-group:not(.p-highlight):not(.p-disabled):hover {
    background-color: $grey-3-background-color !important;
    color: $mainHighLight !important;
    font-weight: bold !important;
}

.p-dropdown-filter-container {
    width: 100%;
}

.ant-select-selector,
.p-dropdown {
    display: flex;
    position: relative;
    cursor: pointer;
    min-width: auto;

    width: 100%;
    // This file cannot use variables
    @extend .typo__label--4;
    color: $input-color;
    border: 1px solid $borderColor !important;
    border-radius: $borderRadius !important;

    .ant-select-selection-item {
        display: flex;
        align-items: center;
        color: $input-color;
        flex-grow: 1;
        > .p-grid {
            // position: fixed;
            > div {
                padding-left: $treeLabelPaddingY;
            }
        }
    }
    .p-dropdown-label {
        // border: 1px solid $borderColor;
        // border-radius: $borderRadius !important;
        flex-grow: 1;
        padding: 0;
        text-align: start;
        padding-left: $inputFieldPaddingX;
        padding-right: $inputFieldPaddingX;
        @extend .center-row-y;
        height: auto;
    }
}

.ant-select-arrow > * {
    line-height: 1;
    color: $borderColor;
}

.p-dropdown-trigger {
    background-color: unset !important;
}

@import "layout/scssVariables/_variables.scss";

.c_datepicker {
    border-radius: $borderRadius !important;
    border: 1px solid $borderColor !important;
    width: 100%;

    .ant-picker-input > input {
        color: #3f3f46 !important;
    }
}

.c_datepicker:hover {
    border: 1px solid $borderColor;
}

.c_datepicker .ant-picker-input > input {
    color: #3f3f46;
}

  .body {
  width: 100%;
  min-height: 70vh;
  display: flex;
  justify-content: space-around;
}

.left,
.right {
  width: 49%;
  border-radius: 8px;
  border: 1px solid #f3f3f3;
  min-height: 50vh;
}

.container {
  padding: 10px 30px;

  .header {
    display: flex;
  }

  .body {
    padding: 10px;
  }

  .headerRight {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &>* {
      position: relative;
      line-break: strict;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:after {
        content: '';
        position: absolute;
        height: 1px;
        border-radius: 3px;
        width: 100%;
        background-color: #00AEEF;
        opacity: .1;
        bottom: -2px;
        left: 0;
      }
    }
  }

  .headerLeft {
    height: 60px;
    width: 60px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.right {
  ul {
    list-style: none;
    margin: 0 !important;
    padding: 1em .5em;
    overflow: scroll;
    max-height: 70vh !important;

    li {
      border: 2px solid #f3f3f3 ;
      border-radius: 8px ;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom :5px ;
      }
    }
    .selected {
      border-color: #00AEEF;
    }
  }
}
.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  button {
    background-color: #00AEEF;
    color: white;
    font-weight: 400;
    &:hover {
      background-color: #3ac1f2;
      color: white;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
}
#wrap-main-landing {
  max-height: initial !important;

  #content-layout .item .row {
    padding-bottom: 100px !important;
  }
}

#wrap-main-landing__footer {
  background-position: bottom right;
  border: 1px solid #0000000F;
  background-size: cover;
}

@media (max-width: 991.98px) {
  #wrap-main-landing {
    .box-bg-content {
      order: 1;

      .box-bg {
        position: relative;
        width: 300px;
        background-size: contain;
        right: initial;
        top: initial;
      }
    }

    .box-date-name {
      order: 2;

      .box-name {
        h3 {
          font-size: 32px;
        }
      }
    }

    .warp-box-name {
      flex-direction: column;
      margin-top: 50px;
      height: fit-content;
    }
  }
}
